import { memo } from "react";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import useTransactionActions from "./useTransactionActions";
import { Transaction, TransactionAction } from "features/transactions/types";
import FakeRedirectAction from "./FakeRedirectAction";
import RefundAction from "./RefundAction";
import NetworkPing from "@mui/icons-material/NetworkPing";
import SettlePingOrderAction from "./SettlePingOrderAction";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ClosePingOrder from "./ClosePingOrder";
import { isAdmin } from "features/auth/helpers";
import useAuth from "features/auth/useAuth";
import PartialRefundAction from "./PartialRefundAction";

type Props = Pick<
  Transaction,
  | 'coreId'
  | 'createdAt'
  | 'via'
  | 'status'
  | 'fundStatus'
  | 'paymentMethod'
  | 'pingTransactionCoreId'
  | 'isEcommerce'
  | 'refundableAmount'
  | 'paymentMethodName'
>;

const TransactionActions = (props: Props) => {
  const {
    sendNotification,
    checkStatus,
    fakeRedirect,
    refund,
    settlePingOrderAction,
    actionLoading
  } = useTransactionActions(props);

  const { user } = useAuth();
  const commonActionProps: Partial<LoadingButtonProps> = {
    variant: "outlined",
    size: "small",
    disabled: !props.coreId
  };

  return (
    <>
      {
        isAdmin(user.roleName) && (
          <Stack px={2} pb={2} direction="row" spacing={1} divider={<Divider />}>
            <LoadingButton
              {...commonActionProps}
              loading={actionLoading[TransactionAction.SendNotification]}
              onClick={sendNotification}
              startIcon={<ArrowCircleUpIcon />}
            >
              Send notification
            </LoadingButton>
            <LoadingButton
              {...commonActionProps}
              loading={actionLoading[TransactionAction.CheckStatus]}
              onClick={checkStatus}
              startIcon={<ArchitectureIcon />}
            >
              Check status
            </LoadingButton>
            <FakeRedirectAction
              {...props}
              {...commonActionProps}
              loading={actionLoading[TransactionAction.FakeRedirect]}
              onClick={fakeRedirect}
              startIcon={<AltRouteIcon />}
            />
            <RefundAction
              {...props}
              ModalProps={{
                DialogProps: {
                  PaperProps: {
                    sx: {
                      maxWidth: '600px !important'
                    }
                  }
                }
              }}
              {...commonActionProps}
              onProceed={refund}
              loading={actionLoading[TransactionAction.Refund]}
              startIcon={<AccountBalanceWalletIcon />}
            />
            <PartialRefundAction
               {...props}
            />
            <SettlePingOrderAction
              {...props}
              {...commonActionProps}
              startIcon={<NetworkPing />}
              onClick={settlePingOrderAction}
              loading={actionLoading[TransactionAction.SettlePingOrder]}
            />
            <ClosePingOrder
              {...props}
              {...commonActionProps}
              startIcon={<CloseFullscreenIcon />}
              onClick={settlePingOrderAction}
              loading={actionLoading[TransactionAction.SettlePingOrder]}
            />


          </Stack>
        )
      }
    </>

  );
};

export default memo(TransactionActions);
