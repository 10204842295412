import { memo } from 'react';
import { selectIsExportConsumersLoading } from 'features/consumers/selectors';
import { TableToolbar, type TableToolbarProps } from 'ui/widgets/Table';

const Toolbar = ({ onExport }: Pick<TableToolbarProps, 'onExport'>) => (
    <TableToolbar
        onExport={onExport}
        selectIsExportLoading={selectIsExportConsumersLoading}
    />
);

export default memo(Toolbar);
