import {
    selectBitsoBanksOptions,
    selectBootstrapData,
    selectCalulationStatus,
    selectCountriesOptions,
    selectIsBootstrapDataLoading,
    selectIsBootstrapDataSucceeded,
    selectParentVisibility,
} from "features/general/selectors";
import { useTypedSelector } from "hooks";
import { Filters as GeneralFilters } from "consts/filters";
import { Filters as TransactionFilters } from "consts/transactions";
import { Filters as MerchantPayoutsFilters } from "consts/merchantPayouts";
import { Filters as ConsumersFilters } from 'consts/consumers';
import { Filters as MerchantFilters } from 'consts/merchants';
import type { Indexed } from "types";
import { selectMerchantOptions } from "features/merchants/selectors";
import { selectPaymentMethodsOptions, selectPaymentMethodsTags } from "features/paymentMethods/selectors";
import { convertToOptions } from "util/transformers";

const Filters = {
    ...MerchantFilters,
    ...TransactionFilters,
    ...MerchantPayoutsFilters,
    ...ConsumersFilters,
    ...GeneralFilters
};

export default function useFiltersModel(filtersModelOverrides: Indexed<Record<string, unknown>> = {}) {
    const model = filterModelFactory({
        ...useTypedSelector(selectBootstrapData),
        Merchants: useTypedSelector(selectMerchantOptions),
        PaymentMethods: useTypedSelector(selectPaymentMethodsOptions),
        [Filters.tag]: useTypedSelector(selectPaymentMethodsTags),
        [Filters.calculationStatus]: useTypedSelector(selectCalulationStatus),
        [Filters.parentVisibility]: useTypedSelector(selectParentVisibility),
        [Filters.nationality]: useTypedSelector(selectCountriesOptions({ id: 'iso2' })),
        [Filters.verifiedNationality]: useTypedSelector(selectCountriesOptions({id: 'iso2'})),
        [Filters.bitsoBankName]: useTypedSelector(selectBitsoBanksOptions),
        ...filtersModelOverrides
    });

    const getFilterOptions = (filterSearchParam: string) => {
        if (filterSearchParam in model) {
            return {
                options: typeof model[filterSearchParam] === "function"
                    ? model[filterSearchParam]()
                    : model[filterSearchParam]
            };
        }

        return {};
    };

    return {
        isLoading: useTypedSelector(selectIsBootstrapDataLoading),
        isSuccess: useTypedSelector(selectIsBootstrapDataSucceeded),
        getFilterOptions
    };
};

function filterModelFactory(bootstrapData: any) {
    const mapFilterWithBootstrapData = new Map<string, any>()
        .set(Filters.settlementStatus, bootstrapData.CsvSettlementStatus)
        .set(Filters.fundStatus, bootstrapData.TransactionFundStatus)
        .set(Filters.status, bootstrapData.TransactionStatus)
        .set(Filters.currency, bootstrapData.Currencies)
        .set(Filters.PSP, bootstrapData.PSPs)
        .set(Filters.MOR, bootstrapData.Domains)
        .set(Filters.incrementStatus, bootstrapData.MerchantPayoutIncrementEnums)
        .set(MerchantPayoutsFilters.transactionCurrency, bootstrapData.TransactionCurrencies)
        .set(MerchantPayoutsFilters.payoutCurrency, bootstrapData.Currencies)
        .set(MerchantPayoutsFilters.taxName, bootstrapData.MerchantPayoutIncrementTaxName)
        .set(MerchantPayoutsFilters.refColFrom, bootstrapData.MerchantPayoutIncrementTaxRefColumnsFrom)
        .set(Filters.direction, bootstrapData.TransactionDirections);

    const filterModel/*: MutableMapped<Array<Option>>*/ = {
        [Filters.isPaid]: bootstrapData.TransactionPayoutStatus,
        [Filters.isSettled]: bootstrapData.TransactionSettlementStatus,
        [Filters.isEcommerce]: bootstrapData.ECommerce,
        [Filters.merchantId]: bootstrapData.Merchants,
        [Filters.methodId]: bootstrapData.PaymentMethods,
        [Filters.via]: bootstrapData.Via,
        [Filters.tag]: bootstrapData[MerchantPayoutsFilters.tag],
        [Filters.paymentMethodTag]: bootstrapData[Filters.paymentMethodTag],
        [Filters.paymentMethodPSP]: bootstrapData[Filters.paymentMethodPSP],
        [Filters.calculationStatus]: bootstrapData[Filters.calculationStatus],
        [Filters.parentVisibility]: bootstrapData[GeneralFilters.parentVisibility],
        [Filters.active]: bootstrapData[Filters.active],
        [Filters.isActive]: bootstrapData[Filters.isActive],
        [Filters.kycStatus]: bootstrapData.KYCStatusEnums,
        [Filters.kycStatusFace]: bootstrapData.KYCStatusesMap,
        [Filters.kycStatusId]: bootstrapData.KYCStatusesMap,
        [Filters.kycStatusAml]: bootstrapData.KYCStatusesMap,
        [Filters.kycStatusAddress]: bootstrapData.KYCStatusesMap,
        [Filters.kycStatusPhone]: bootstrapData.KYCStatusesMap,
        [Filters.kycStatusEidv]: bootstrapData.KYCStatusesMap,
        [Filters.samePersonIdentifierSource]: bootstrapData.SamePersonIdentifierSourceTypes,
        [Filters.obResultStatus]: bootstrapData.ObResultStatuses,
        [Filters.hasImmunityForForbiddenWords]: bootstrapData[Filters.hasImmunityForForbiddenWords],
        [Filters.manualBulkKycImportStatus]: bootstrapData[Filters.manualBulkKycImportStatus],
        [Filters.cardIssuer]: bootstrapData.CardIssuer,
        [Filters.nationality]: bootstrapData[Filters.nationality],
        [Filters.verifiedNationality]: bootstrapData[Filters.verifiedNationality],
        [Filters.createdByMerchantId]: bootstrapData.Merchants,
        [Filters.updatedByMerchantId]: bootstrapData.Merchants,
        [Filters.bitsoBankName]: bootstrapData[Filters.bitsoBankName]
    };

    for (const filterName of Object.values(Filters)) {
        if (mapFilterWithBootstrapData.has(filterName)) {
            const filterOptions = mapFilterWithBootstrapData.get(filterName);

            filterModel[filterName] = filterOptions.some((filterOption: string) => typeof filterOption === 'object')
                ? filterOptions
                : convertToOptions(filterOptions);
        }
    }

    return filterModel;
}
