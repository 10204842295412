import { useMemo } from "react";
import { ValidationError, mixed, object, string } from "yup";
import {
    AVATAR_MAX_SIZE,
    AVATAR_SUPPORTED_FORMATS
} from "consts/validation";
import { useValidationSchema as useMerchantValidationSchema } from "../Merchant";
import { FormField } from "./types";

export default function useValidationSchema() {
    const merchantCreateValidationSchema = useMerchantValidationSchema();

    return useMemo(() => merchantCreateValidationSchema.concat(
        object().shape({
            [FormField.Groups]: string()
                .required()
                .label('Groups'),
            [FormField.Avatar]: mixed()
                .test('fileSize', value => {
                    if (value) {
                        return !(value.size <= AVATAR_MAX_SIZE) && new ValidationError(
                            'File size is too large',
                            value.size,
                            FormField.Avatar,
                            'fileSize'
                        );
                    }

                    return true;
                })
                .test('fileFormat', value => {
                    if (value) {
                        const supportedFormats = AVATAR_SUPPORTED_FORMATS
                            .map(format => format.split('/').pop());

                        return supportedFormats
                            .includes(value.name.split('.').pop()) || new ValidationError(
                                `Only ${supportedFormats.join(', ')} files are allowed`,
                                value.name,
                                FormField.Avatar,
                                'fileFormat'
                            );
                    }

                    return true;
                })
        })
    ), [merchantCreateValidationSchema]);
};
