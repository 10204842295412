import { memo } from "react";
import Typography from "@mui/material/Typography";
import { NoDataOverlay } from "ui/molecules/TableGrids";

const NoRowsOverlay = () => (
    <NoDataOverlay>
        <Typography
            variant='h6'
            color='textSecondary'
            textAlign='center'
        >
            Looks like there are no consumers found for selected filters… <br />
            Try to change filters or search criteria.
        </Typography>
    </NoDataOverlay>
);

export default memo(NoRowsOverlay);
