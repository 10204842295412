import type { BootstrapableForm } from "types";

export const FormField = {
    Address: 'fullAddress',
    City: 'cityAddress',
    Company: 'fullName',
    Country: 'countryAddress',
    DefaultLanguage: 'defaultLanguage',
    Phone: 'phone',
    State: 'stateAddress',
    VatNumber: 'vatAddress',
    ZipCode: 'zipAddress',
    Groups: 'groups',
    DoNotBlacklistOnChargeback: 'doNotBlacklistOnChargeback',
    DoNotBlacklistOnRefund: 'doNotBlacklistOnRefund'
} as const;

export type Form<
    TFormEntity,
    TBootsrapData,
    TForm,
> =
    & BootstrapableForm<TFormEntity, TBootsrapData>
    & TForm;
