import type { ID } from "types";
import { useAppStore } from "hooks";
import { useDomainRepository } from "hooks/useDomainState";
import { useSimpleRequest, type UseSimpleRequestReturnCallbackArg } from "hooks/useRequest";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Filters } from "consts/merchants";
import { HttpVerb } from "consts/api";
import BusinessLogicException from "exceptions/BusinessLogicException";
import { createOrUpdateMerchantSettings, getMerchantSettings, getMerchantUsers } from "features/merchants/api";
import type { Merchant, MerchantGroup } from "features/merchants/types";
import useMerchantActions from "features/merchants/useMerchantActions";
import { createMerchantsGroupsPivot, deleteMerchantsGroupsPivot } from "features/pivots/api";
import { selectMerchantGroup } from "features/merchants/selectors";
import reducer from "./reducer";
import stateFactory from "./state";
import Repository from "./repository";
import {
    MerchantDetailActionType,
    type MerchantSettingsActionPayload,
    type MerchantDetailRepositoryState
} from "./types";

export default function useRepository(merchant: Merchant) {
    const store = useAppStore();

    const request = useSimpleRequest();

    const { putMerchant } = useMerchantActions();

    const merchantGroupId = new Map<HttpVerb, (merchant: Partial<Merchant>) => ID>()
        .set(HttpVerb.Post, ({
            groups
        }: Partial<Merchant>) => (
            selectMerchantGroup(String(groups))(store.getState())!.coreId
        ))
        .set(HttpVerb.Delete, ({ groupPivots }: Partial<Merchant>) => (
            groupPivots![0].merchantGroupPivotId
        ));

    const repository = useDomainRepository<
        MerchantDetailRepositoryState,
        MerchantDetailActionType
    >({
        reducer,
        initializerArg: stateFactory(),
        factory: (state, dispatch) => new Repository(
            state,
            dispatch,
            () => request(() => getMerchantSettings(
                RequestQueryMapper.from()
                    .contains(Filters.merchantId, String(merchant.coreId))
                    .toString()
            )),
            () => request(() => getMerchantUsers(
                RequestQueryMapper.from()
                    .contains(Filters.merchantId, String(merchant.coreId))
                    .toString()
            )),
            async (merchant: Partial<Merchant>) => {
                const [request] = putMerchant(merchant);
                await request;
            },
            (
                merchantSettings: MerchantSettingsActionPayload,
                requestSettings?: UseSimpleRequestReturnCallbackArg
            ) => request(() => createOrUpdateMerchantSettings(merchantSettings), requestSettings),
            (
                merchant: Partial<Merchant & MerchantGroup>,
                requestSettings?: UseSimpleRequestReturnCallbackArg
            ) => request(() => createMerchantsGroupsPivot({
                merchantId: merchant.coreId,
                merchantGroupId: merchantGroupId.get(HttpVerb.Post)!(merchant),
                doNotBlacklistOnChargeback: merchant.doNotBlacklistOnChargeback,
                doNotBlacklistOnRefund: merchant.doNotBlacklistOnRefund
            }), requestSettings),
            (
                merchant: Partial<Merchant>,
                requestSettings?: UseSimpleRequestReturnCallbackArg
            ) => request(() => deleteMerchantsGroupsPivot(
                merchantGroupId.get(HttpVerb.Delete)!(merchant),
            ), requestSettings)
        )
    });

    const inject = () => {
        if (repository instanceof Repository) {
            return repository;
        }

        throw new BusinessLogicException('My life completely sucks', {});
    };

    return {
        repository,
        inject
    };
};
