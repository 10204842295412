import { memo, type MouseEvent } from "react";
import { useUnsavedChangesContext } from "ui/widgets/UnsavedChanges";
import type { EnabledMerchantsProps, MerchantListProps } from "./types";
import MerchantList from "./MerchantList";
import useEnabledMerchants from "./useEnabledMerchants";
import type { ID } from "types";

type Props = MerchantListProps & EnabledMerchantsProps;

const EnabledMerchants = ({
    selectApiState,
    disabled,
    onClick,
    ...merchantListProps
}: Props) => {
    const { hasUnsavedChanges } = useUnsavedChangesContext();

    const { onMerchantSelect, isProcessing } = useEnabledMerchants({
        selectApiState
    });

    const handleOnClick = (event: MouseEvent<HTMLAnchorElement>, coreId: ID) => {
        if (hasUnsavedChanges()) {
            return;
        }

        onClick?.(event, coreId);
        onMerchantSelect(coreId);
    };

    return (
        <MerchantList
            {...merchantListProps}
            onClick={handleOnClick}
            disabled={disabled || isProcessing}
        />
    );
};

export default memo(EnabledMerchants);
