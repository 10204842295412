import { useFormik, type FormikConfig } from "formik";
import { trimObjectValues } from "util/support";
import { toBooleanSwitch } from "util/casts";
import { FormField, type MerchantProfileInfo } from "./types";
import type { Merchant, MerchantGroup } from "features/merchants/types";
import useValidationSchema from "./useValidationSchema";
import { getInitialValues as getMerchantInitialValues } from "../Merchant";

export const getInitialValues = (initialValues: Partial<Merchant>) => ({
    [FormField.Avatar]: '',
    [FormField.ShowPrimaryContactFullName]: toBooleanSwitch(initialValues.showPrimaryContact),
    ...getMerchantInitialValues(initialValues),
});

export const useForm = (merchantProfileInfoForm: FormikConfig<MerchantProfileInfo>) => {
    const {
        initialValues,
        ...restFormArgs
    } = merchantProfileInfoForm;

    return useFormik({
        enableReinitialize: true,
        validateOnMount: true,
        ...restFormArgs,
        validationSchema: useValidationSchema(),
        // isInitialValid: validationSchema.isValidSync(initialValues),
        initialValues: trimObjectValues<
            Partial<Merchant & MerchantGroup>,
            Merchant & MerchantGroup
        >(getInitialValues(initialValues))
    });
};