import { ApiState } from "infrastructure/api";
import type { RootState } from "infrastructure/store";

export const selectAssertConsumersTableLoadingState = (apiState: ApiState) => (state: RootState) =>
    Object.is(state.consumers.tableLoadingState, apiState);

export const selectIsConsumersUninitialized = (state: RootState) =>
    !selectAssertConsumersTableLoadingState(ApiState.Idle)(state);

export const selectIsConsumersLoading = selectAssertConsumersTableLoadingState(ApiState.Pending);

export const selectConsumersSlice = (state: RootState) =>
    state.consumers.tableSlice;

export const selectIsExportConsumersLoading = (state: RootState) =>
    state.consumers.exportLoadingState === ApiState.Pending;
