import { useAppDispatch, useRequest } from "hooks";
import type { ThunkReturnType } from "types";
import {
    changeExportMerchantLedgersLoading as changeExportLoading,
    resetMerchantLedgers as resetMerchantLedgersAction
} from "./slice";
import type { Ledger, LedgerResponse, LedgersResponse } from "./types";
import { getMerchantLedgersThunk, putMerchantLedgersThunk } from "./thunks";
import useExportTable from "hooks/useExportTable";
import { ApiRouteTypes } from "consts/enpoints/api";

const useMerchantLedgerActions = () => {
    const dispatch = useAppDispatch();

    const getMerchantLedgers = useRequest<ThunkReturnType<LedgersResponse>, string | undefined>({
        thunk: getMerchantLedgersThunk
    });
    const putMerchantLedgers = useRequest<ThunkReturnType<LedgerResponse>, Partial<Ledger>>({
        thunk: putMerchantLedgersThunk
    });

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetLedgers
    });

    const resetMerchantLedgers = () =>
        dispatch(resetMerchantLedgersAction());

    return {
        getMerchantLedgers,
        putMerchantLedgers,
        resetMerchantLedgers,
        downloadCsv
    };
};

export default useMerchantLedgerActions;
