import { useTypedSelector } from "hooks";
import type { Merchant } from "features/merchants/types";
import { selectMerchantById } from "features/merchants/selectors";
import { useResourceContext } from "ui/widgets/Resource";
import { useEffect, useRef } from "react";
import { compareIds, isEqual } from "util/support";

export default function useSyncMerchantUpdates() {
    const { resource, ...helpers } = useResourceContext<Merchant>();

    const helpersRef = useRef(helpers);
    helpersRef.current = helpers;

    const merchant = useTypedSelector(selectMerchantById(resource?.coreId));

    useEffect(() => {
        if (!merchant || !resource || !compareIds(merchant.coreId, resource.coreId)) {
            return;
        }

        if (!isEqual(merchant, resource)) {
            helpersRef.current.updateResource(merchant);
        }
    }, [merchant, resource]);
};
