import { toBooleanSwitch } from "util/casts";
import useCountries from "features/general/useCountries";
import { ProfileInfo } from "ui/forms/Merchant";
import useMerchantDetail from "../../useMerchantDetail";
import { BooleanSwitchValue } from "consts/general";

export default function useMerchantProfileInfo(): ProfileInfo.MerchantProfileInfoForm {
    const { merchant } = useMerchantDetail();
    const { countries, isCountriesLoading } = useCountries();

    const onSubmit = async (_: Partial<ProfileInfo.MerchantProfileInfo>) => {};

    let doNotBlacklistOnChargeback = BooleanSwitchValue.Off;
    let doNotBlacklistOnRefund = BooleanSwitchValue.Off;

    try {
        doNotBlacklistOnChargeback = toBooleanSwitch(merchant.groupPivots[0]?.doNotBlacklistOnChargeback);
        doNotBlacklistOnRefund = toBooleanSwitch(merchant.groupPivots[0]?.doNotBlacklistOnRefund);
    }catch {}

    return {
        ...ProfileInfo.useForm({
            initialValues: {
                ...merchant,
                doNotBlacklistOnChargeback,
                doNotBlacklistOnRefund,
            },
            onSubmit
        }),
        onSaveOrCreate: onSubmit,
        bootstrapData: {
            countries
        },
        isBootstrapDataLoading: isCountriesLoading,
    };
};
