import { ApiState } from "infrastructure/api";
import type { RootState } from "infrastructure/store";
import type { AggregateRoot } from "types";
import type { Transaction } from "./types";
import { compareIds } from "util/support";

export const selectIsTransactionsUninitialized = (state: RootState) =>
  state.transactions.transactionsLoadingState === ApiState.Idle;

export const selectIsTransactionsLoading = (state: RootState) =>
  state.transactions.transactionsLoadingState === ApiState.Pending;

export const selectIsTransactionDetailsLoading = (state: RootState) =>
  state.transactions.transactionDetailsLoadingState === ApiState.Pending;

export const selectIsTransactionHistoryLoading = (state: RootState) =>
  state.transactions.transactionHistoryLoadingState === ApiState.Pending;

export const selectIsExportTransactionsLoading = (state: RootState) =>
  state.transactions.exportLoadingState === ApiState.Pending;

export const selectTransactionSlice = (state: RootState) =>
  state.transactions.transactionSlice;

export const selectTransaction = ({ coreId }: AggregateRoot, fallback?: unknown) => (state: RootState) =>
  selectTransactionSlice(state)
    ?.data
    .find((transaction: Transaction) => compareIds(transaction.coreId, coreId)) ?? fallback;
