import { useEffect, useRef, useState } from "react";
import { useModal } from "ui/atoms/Modal";

export default function useResource<TResource = unknown>() {
    const modal = useModal();
    const [resource, setResource] = useState<TResource>();
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const updateResource = (resource: TResource) => setResource(resource);

    const onOpen = (resource: TResource) => {
        setResource(resource);
        modal.onOpen();
    };

    const onClose = () => {
        clearTimeout(timeoutRef.current);
        modal.onClose();
        timeoutRef.current = setTimeout(() => setResource(undefined), 300);
    };

    const getIsNew = () => !resource;

    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    return {
        ...modal,
        getIsNew,
        onOpen,
        onClose,
        resource,
        updateResource
    };
};
