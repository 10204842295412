import { ApiRoutes } from "consts/enpoints/api";
import { fetchApi } from "infrastructure/api";
import type { ID } from "types";
import type { MerchantsGroupsPivot, MerchantsMethodsPivot, MerchantsNotificationChannelsPivot } from "./types";

export const getMerchantsMethodsPivot = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetMerchantsMethodsPivot}${queryString}`);

export const createMerchantsMethodsPivot = (body: Pick<
    MerchantsMethodsPivot,
    | 'merchantId'
    | 'methodId'
    | 'MOR'
> & Partial<
    Omit<
        MerchantsMethodsPivot,
        | 'merchantId'
        | 'methodId'
        | 'MOR'
    >
>) =>
    fetchApi(ApiRoutes.PostMerchantsMethodsPivot, {
        method: 'POST',
        body
    });

export const updateMerchantsMethodsPivot = ({
    coreId,
    ...body
}: Pick<
    MerchantsMethodsPivot,
    | 'coreId'
> & Partial<Omit<MerchantsMethodsPivot, 'coreId'>>) =>
    fetchApi(ApiRoutes.PutMerchantsMethodsPivot(coreId), {
        method: 'PUT',
        body
    });

export const getMerchantsNotificationChannelsPivot = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetMerchantsNotificationChannelsPivot}${queryString}`);

export const updateOrCreateMerchantsNotificationChannelsPivot = (body:
    & Pick<
        MerchantsNotificationChannelsPivot,
        | 'merchantId'
        | 'notificationChannelId'
    >
    & Partial<MerchantsNotificationChannelsPivot>
) => {
    const { merchantId, notificationChannelId } = body;

    return fetchApi(ApiRoutes.PutMerchantsNotificationChannelsPivot(
        merchantId,
        notificationChannelId
    ), {
        method: 'PUT',
        body
    });
};

export const createMerchantsGroupsPivot = (body: Partial<MerchantsGroupsPivot>) =>
    fetchApi(ApiRoutes.PostMerchantsGroupsPivot, {
        method: 'POST',
        body
    });

export const deleteMerchantsGroupsPivot = (coreId: ID) =>
    fetchApi(ApiRoutes.DeleteMerchantsGroupsPivot(coreId), {
        method: 'DELETE'
    });

export const getMerchantsMethodsPivotHistory = (queryString = '') =>
    fetchApi(`${ApiRoutes.GetMerchantsMethodsPivotHistory}${queryString}`);