import type { PayloadAction } from "@reduxjs/toolkit";
import { useCallback, useRef } from "react";
import { useAppDispatch } from "./store";

const useResetState = (resetState: () => PayloadAction<undefined>) => {
    const dispatch = useAppDispatch();
    const helpers = {
        dispatch,
        resetState
    };
    const helpersRef = useRef(helpers);
    helpersRef.current = helpers;

    return useCallback(() => {
        const {
            dispatch,
            resetState
        } = helpersRef.current;

        dispatch(resetState());
    }, []);
};

export default useResetState;
