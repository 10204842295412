import { useMemo } from "react";
import { mixed, object, string } from "yup";
import { REGEXP } from "consts/validation";
import { BooleanSwitchValue } from "consts/general";
import type { Merchant } from "features/merchants/types";
import { FormField } from "./types";

export const getInitialValues = (initialValues: Partial<Merchant>) => ({
    [FormField.Company]: '',
    [FormField.Address]: '',
    [FormField.City]: '',
    [FormField.VatNumber]: '',
    [FormField.State]: '',
    [FormField.Phone]: '',
    [FormField.ZipCode]: '',
    [FormField.DefaultLanguage]: '',
    [FormField.Country]: '',
    [FormField.Groups]: '',
    [FormField.DoNotBlacklistOnChargeback]: BooleanSwitchValue.Off,
    [FormField.DoNotBlacklistOnRefund]: BooleanSwitchValue.Off,
    ...initialValues,
});

export const useValidationSchema = () =>
    useMemo(() => object().shape({
        [FormField.Company]: string()
            .max(255)
            .required()
            .label('Company'),
        [FormField.Address]: string()
            .max(255)
            .required()
            .label('Address'),
        [FormField.City]: string()
            .max(255)
            .optional()
            .label('City'),
        [FormField.VatNumber]: string()
            .max(255)
            .matches(/^\d+$/, 'VAT Number must contain only numbers')
            .optional()
            .label('VAT Number'),
        [FormField.State]: string()
            .max(255)
            .optional()
            .label('State'),
        [FormField.Phone]: string()
            .matches(REGEXP.Phone, 'Phone number is not valid')
            .max(20)
            .optional(),
        [FormField.ZipCode]: string()
            .max(10)
            .matches(/^\d+$/, 'Zip code must contain only numbers')
            .optional()
            .label('Zip Code'),
        [FormField.DefaultLanguage]: string()
            .max(255)
            .optional()
            .label('Default Language'),
        [FormField.Country]: mixed()
        .optional()
            .label('Country')
    }), []);
