import { memo, useCallback } from 'react';
import {
    selectIsTransactionsLoading,
    selectIsTransactionsUninitialized,
    selectTransactionSlice
} from 'features/transactions/selectors';
import Table from "ui/widgets/Table";
import TransactionListToolbar from './TransactionListToolbar';
import { TableGridMode } from 'consts/table';
import { date, FilterVariant } from 'consts/filters';
import { multi, single, text } from 'consts/transactionList';
import useActions from 'features/transactions/useActions';
import type { GridRowIdGetter } from '@mui/x-data-grid';
import type { Transaction } from 'features/transactions/types';
import useRequestQueryOverrideDecorator from './useRequestQueryOverrideDecorator';

const TransactionList = () => {
    const {
        getTransactions,
        downloadCsv,
    } = useActions();

    const getRowId: GridRowIdGetter<Transaction> = useCallback(({ transactionId }: Transaction) =>
        transactionId, []);

    return (
        <Table
            mode={TableGridMode.TransactionList}
            selectIsTableUninitialized={selectIsTransactionsUninitialized}
            selectIsTableLoading={selectIsTransactionsLoading}
            selectTableDataSlice={selectTransactionSlice}
            fetchTableData={getTransactions}
            downloadCsv={downloadCsv}
            filterTypes={new Map<FilterVariant, string[]>([
                [FilterVariant.Text, text],
                [FilterVariant.DateRange, date],
                [FilterVariant.MultiOption, multi],
                [FilterVariant.SingleOption, single]
            ])}
            Toolbar={TransactionListToolbar}
            getRowId={getRowId}
            requestQueryOverrideDecorator={useRequestQueryOverrideDecorator}
        />
    );
};

export default memo(TransactionList);
