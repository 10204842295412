import { MerchantGroupName } from "consts/merchants";
import { Filters, Relations } from "consts/transactions";
import { RequestQueryMapper } from "util/request-query-mapper";;

export default function useRequestQueryOverrideDecorator(
    requestQueryAggregator: (
        fontendQueryParams: URLSearchParams,
        backendQueryParams: URLSearchParams
    ) => void
) {
    return (
        fontendQueryParams: URLSearchParams,
        backendQueryParams: URLSearchParams
    ) => {
        RequestQueryMapper.applyWithCustomQueryAggregator({
            fontendQueryParams,
            backendQueryParams,
            queryExtensionMap: new Map<
                string,
                (values: string[], requestQueryMapper: RequestQueryMapper) => void
            >()
                .set(Filters.isEcommerce, ([isEcommerce]: string[], requestQueryMapper: RequestQueryMapper) => [
                    () => requestQueryMapper
                        .containsNotIn(Relations.MerchantGroupName, MerchantGroupName.ECommerce),
                    () => requestQueryMapper
                        .containsIn(Relations.MerchantGroupName, MerchantGroupName.ECommerce)
                ][Number(isEcommerce)]())
        });

        return requestQueryAggregator(fontendQueryParams, backendQueryParams);
    };
};
