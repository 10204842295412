import {
    FormField as MerchantFormField,
} from "../Merchant";
import type { MerchantCreateForm } from "ui/forms/Merchant/Create";
import type { Merchant, MerchantGroup } from "features/merchants/types";
import type { useForm } from "./useForm";

export const FormField = {
    ...MerchantFormField,
    Avatar: 'avatar',
    ShowPrimaryContactFullName: 'showPrimaryContact'
} as const;

export type MerchantProfileInfo = Pick<
    Merchant,
    | 'fullAddress'
    | 'fullName'
    | 'showPrimaryContact'
    | 'cityAddress'
    | 'vatAddress'
    | 'stateAddress'
    | 'phone'
    | 'zipAddress'
    | 'defaultLanguage'
    | 'countryAddress'
    | 'groups'
> & Pick<
    MerchantGroup,
    | 'doNotBlacklistOnChargeback'
    | 'doNotBlacklistOnRefund'
>;

export type MerchantProfileInfoForm = MerchantCreateForm<ReturnType<typeof useForm>>;
