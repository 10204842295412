export const enum ResponseCode {
    Ok = 200,
    Created = 201,
    BadRequest = 400,
    Unathenticated = 401,
    UnprocessibleEntity = 422
};

export const enum ResponseSettlementStatus {
    Fullfilled = 'fulfilled',
    Rejected = 'rejected'
};

export const enum ModifyStrategy {
    Create,
    Update
};

export const enum ElasticSearchDataType {
    Double = 'double',
    Integer = 'integer',
    Text = 'text',
    Datetime = 'datetime'
};

export const enum HttpVerb {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Delete = 'DELETE'
};
