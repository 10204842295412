import type { PayloadAction } from "@reduxjs/toolkit";
import type { WritableDraft } from "immer/dist/internal"
import { ApiState } from "infrastructure/api";
import type { ID, ThunkReturnType } from "types";
import type {
    Ledger,
    LedgerResponse,
    LedgersResponse,
    Merchant,
    MerchantGroupsResponse,
    MerchantResponse,
    MerchantsState
} from "./types";
import { deleteTableSliceEntity } from "infrastructure/store/helpers";
import thunkReducerFactory from "infrastructure/store/reducers/thunkReducerFactory";
import { MerchantTabs } from "consts/merchants";
import { compareIds } from "util/support";
import type { User, UsersResponse } from "features/users/types";
import { fromPaginateResourceResponse } from "util/api";

export const {
    requestFulfilled,
    requestLoading,
    requestRejected
} = thunkReducerFactory();

export const merchantGroupsRequestLoading = (state: WritableDraft<MerchantsState>) => {
    state[MerchantTabs.Merchants].bootstrapData.merchantGroups.loadingState = ApiState.Pending;
};

export const merchantGroupsRequestFulfilled = (
    state: WritableDraft<MerchantsState>,
    { payload }: PayloadAction<ThunkReturnType<MerchantGroupsResponse>>) => {
    state[MerchantTabs.Merchants].bootstrapData.merchantGroups.loadingState = ApiState.Succeeded;
    state[MerchantTabs.Merchants].bootstrapData.merchantGroups.slice = fromPaginateResourceResponse(payload!);
};

export const merchantGroupsRequestRejected = (state: WritableDraft<MerchantsState>) => {
    state[MerchantTabs.Merchants].bootstrapData.merchantGroups.loadingState = ApiState.Failed;
};

export const merchantCreateRequestLoading = (state: WritableDraft<MerchantsState>) => {
    state[MerchantTabs.Merchants].entityLoadingState = ApiState.Pending;
};

export const merchantCreateRequestRejected = (state: WritableDraft<MerchantsState>) => {
    state[MerchantTabs.Merchants].entityLoadingState = ApiState.Failed;
};

export const merchantModelUpdateRequestFullfilled = (
    state: WritableDraft<MerchantsState>,
    { payload }: PayloadAction<ThunkReturnType<MerchantResponse>>
) => {
    state.merchants.tableSlice!.data = state.merchants.tableSlice!.data.map((merchant: Merchant) => {
        if (compareIds(merchant.coreId, payload!.data.coreId)) {
            return ({
                ...merchant,
                ...payload!.data
            });
        }

        return merchant;
    });
};

export const merchantUpdateRequestFulfilled = (
    state: WritableDraft<MerchantsState>,
    payloadAction: PayloadAction<ThunkReturnType<MerchantResponse>>
) => {
    const { payload } = payloadAction;

    state[MerchantTabs.Merchants].entityLoadingState = ApiState.Succeeded;

    state[MerchantTabs.Merchants].tableSlice!.data =
        state[MerchantTabs.Merchants].tableSlice!.data.map((merchant: Merchant) => {
            if (compareIds(merchant.coreId, payload!.data.coreId)) {
                return ({
                    ...merchant,
                    ...payload!.data
                });
            }

            return merchant;
        });

    merchantModelUpdateRequestFullfilled(state, payloadAction);
};

export const merchantModelCreateRequestFulfilled = (
    state: WritableDraft<MerchantsState>,
    { payload }: PayloadAction<ThunkReturnType<MerchantResponse>>
) => {
    state.merchants.tableSlice!.data.unshift(payload!.data);
    state.merchants.tableSlice!.total += 1;
}

export const merchantCreateRequestFulfilled = (
    state: WritableDraft<MerchantsState>,
    payloadAction: PayloadAction<ThunkReturnType<MerchantResponse>>) => {
    const { payload } = payloadAction;

    state[MerchantTabs.Merchants].entityLoadingState = ApiState.Succeeded;

    state[MerchantTabs.Merchants].tableSlice!.data.unshift(payload!.data);
    state[MerchantTabs.Merchants].tableSlice!.total += 1;

    merchantModelCreateRequestFulfilled(state, payloadAction);
};

export const merchantUsersRequestLoading = (state: WritableDraft<MerchantsState>) => {
    state.merchantUsers.tableLoadingState = ApiState.Pending;
};

export const merchantUsersRequestFulfilled = (
    state: WritableDraft<MerchantsState>,
    { payload }: PayloadAction<ThunkReturnType<UsersResponse>>
) => {
    state.merchantUsers.tableLoadingState = ApiState.Succeeded;
    state.merchantUsers.tableSlice = payload!.data;
};

export const merchantUsersRequestRejected = (state: WritableDraft<MerchantsState>) => {
    state.merchantUsers.tableLoadingState = ApiState.Failed;
};

export const updateOrAddMerchantByIdUsers = (state: WritableDraft<MerchantsState>, { payload }: PayloadAction<User>) => {
    if (!state.merchantUsers.tableSlice) {
        return;
    }

    let isUpdated = false;

    state.merchantUsers.tableSlice.data = state.merchantUsers.tableSlice.data.map((userEntity: User) => {
        if (compareIds(userEntity.coreId, payload.coreId)) {
            isUpdated = true;

            return ({
                ...userEntity,
                ...payload
            });
        }

        return userEntity;
    });

    if (!isUpdated) {
        state.merchantUsers.tableSlice.data.unshift(payload);
        state.merchantUsers.tableSlice.to += 1;
        state.merchantUsers.tableSlice.total += 1;
    }
};

export const merchantLedgersRequestLoading = (state: WritableDraft<MerchantsState>) => {
    state.ledgers.tableLoadingState = ApiState.Pending;
};

export const merchantLedgersRequestFulfilled = (
    state: WritableDraft<MerchantsState>,
    { payload }: PayloadAction<ThunkReturnType<LedgersResponse>>
) => {
    state.ledgers.tableLoadingState = ApiState.Succeeded;
    state.ledgers.tableSlice = payload!.data;
};

export const merchantLedgersRequestRejected = (state: WritableDraft<MerchantsState>) => {
    state.ledgers.tableLoadingState = ApiState.Failed;
};

export const updateOrAddMerchantLedger = (state: WritableDraft<MerchantsState>,
    { payload }: PayloadAction<ThunkReturnType<LedgerResponse>>) => {
    if (!state.ledgers.tableSlice) {
        return;
    }

    let isUpdated = false;
    state.ledgers.tableLoadingState = ApiState.Succeeded;
    state.ledgers.tableSlice.data = state.ledgers.tableSlice.data.map((ledgerEntity: Ledger) => {
        if (payload && compareIds(ledgerEntity.pki, payload.data.pki)) {
            isUpdated = true;

            return ({
                ...ledgerEntity,
                ...payload
            });
        }

        return ledgerEntity;
    });

    if (!isUpdated) {
        state.ledgers.tableSlice.data.unshift(payload!.data);
        state.ledgers.tableSlice.to += 1;
        state.ledgers.tableSlice.total += 1;
    }
};

export const deleteMerchantUser = (state: WritableDraft<MerchantsState>, { payload }: PayloadAction<ID>) => {
    deleteTableSliceEntity(state[MerchantTabs.MerchantUsers], payload);
    deleteTableSliceEntity(state.merchantUsers, payload);
};
