import { memo } from "react";
import type { GridRenderCellParams } from "@mui/x-data-grid-premium";
import { convertToBooleanSwitch } from "util/transformers";
import { useTypedSelector } from "hooks";
import { selectIsMerchantLedgersLoading } from "features/merchants/selectors";
import {  getLedgers } from "features/merchants/api";
import type { LedgerResponse } from "features/merchants/types";
import { StatusResourceTableCell } from "../../../shared/TableCells";
import useMerchantLedgerActions from "features/merchants/useMerchantLedgerActions";
import { RequestQueryMapper } from "util/request-query-mapper";
import { Paginate } from "consts/table";
import { PER_PAGE_SIZE } from "consts/transactions";
import { Filters } from "consts/kyc";

const LedgerEnabled = (props: GridRenderCellParams) => {
    const { putMerchantLedgers} = useMerchantLedgerActions();
    const isProcessing = useTypedSelector(selectIsMerchantLedgersLoading);


    const handleProcess = async (isEnabled: boolean, { row }: GridRenderCellParams) => {
        const isLedgerEnabled = convertToBooleanSwitch(isEnabled);

        const [response] = await putMerchantLedgers({
            coreId: row.coreId,
            isEnabled: isLedgerEnabled
        });

        Promise.allSettled([
            getLedgers(
                RequestQueryMapper.from()
                    .containsIn(Filters.merchantId, String(row.merchantId))
                    .contains(Paginate.perPage,  String(PER_PAGE_SIZE))
                    .toString()
            ),
            response
        ]);

        return response as Promise<LedgerResponse>;
    };

    const getChecked = ({ value }: GridRenderCellParams) => !!Number(value);

    const getTransitionStatus = () => {
        if (getChecked(props)) {
            return 'disable';
        }

        return 'enable';
    };

    const defaultDialogContent = {
        dialogContent: [
            `Are you sure you want to ${getTransitionStatus()} ledger?`
        ],
        actionContent: 'Yes'
    };

    return (
        <StatusResourceTableCell
            {...props}
            isProcessing={isProcessing}
            getChecked={getChecked}
            handleProcess={handleProcess}
            dialogContentDescription={defaultDialogContent}
        />
    );
};

export default memo(LedgerEnabled);