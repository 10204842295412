import type { WritableDraft } from 'immer/dist/internal';
import type { AggregateRoot, ID, Paginator } from 'types';
import type { ResourceSlice } from './types';
import { compareIds } from 'util/support';

export const deleteTableSliceEntity = <T extends AggregateRoot>(
    resourceSlice: WritableDraft<Pick<ResourceSlice<Paginator<T>>, 'tableSlice'>>,
    entityId?: ID | null
) => {
    const { tableSlice } = resourceSlice;

    if (!tableSlice || !entityId) {
        return;
    }

    resourceSlice.tableSlice!.data = tableSlice!.data.filter(({ coreId }: AggregateRoot) => (
        !compareIds(coreId, entityId)
    ));

    resourceSlice.tableSlice!.to -= 1;
    resourceSlice.tableSlice!.total -= 1;
};
