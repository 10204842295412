import { type Dispatcher, DomainRepository } from "hooks/useDomainState";
import {
    MerchantProfileActionType,
    type MerchantProfileRepository,
    type MerchantProfileRepositoryState,
    type MerchantSettingsActionPayload
} from "./types";
import { ApiState } from "infrastructure/api";
import type { PaginateResourceResponse, ResourceResponse } from "types";
import type {
    Merchant,
    MerchantPayoutAbstractAccount,
    MerchantPayoutAccount,
    MerchantPayoutCryptoAccount
} from "features/merchants/types";
import { getPromiseSettledResourceResult } from "util/resource";
import { MerchantProfileTab } from "../MerchantProfileProvider";
import type { MerchantGroupRepository, MerchantRepository, MerchantSettingsRepository } from "../../repository";
import type { MerchantProfileInfoForm } from "ui/forms/Merchant/ProfileInfo";
import type { MerchantProfileFinance } from "ui/forms/Merchant/ProfileFinance";
import { AccountType } from "consts/merchants";
import { fromPaginateResourceResponse } from "util/api";

export class Repository extends DomainRepository<
    MerchantProfileRepositoryState,
    MerchantProfileActionType
> implements MerchantProfileRepository {
    public constructor(
        public readonly state: MerchantProfileRepositoryState,
        protected readonly dispatch: Dispatcher<MerchantProfileActionType>,
        private readonly merchantRepository: MerchantRepository & MerchantSettingsRepository & MerchantGroupRepository,
        private readonly getPayoutAccountsRequest: () => Promise<PaginateResourceResponse<MerchantPayoutAccount>>,
        private readonly getPayoutCryptoAccountsRequest: () => Promise<PaginateResourceResponse<MerchantPayoutCryptoAccount>>,
        private readonly createPayoutAccountRequest: (account: MerchantProfileFinance) => Promise<ResourceResponse<MerchantPayoutAbstractAccount>>,
        private readonly updatePayoutAccountRequest: (account: MerchantPayoutAbstractAccount) => Promise<ResourceResponse<MerchantPayoutAbstractAccount>>,
        private readonly deletePayoutAccountRequest: (account: MerchantPayoutAbstractAccount) => Promise<ResourceResponse<null>>
    ) {
        super(state, dispatch);
    }

    public get accounts() {
        return [
            ...this.state[MerchantProfileTab.Finance][AccountType.Bank],
            ...this.state[MerchantProfileTab.Finance][AccountType.Crypto]
        ];
    }

    public get account() {
        return this.state[MerchantProfileTab.Finance].account;
    }

    public get accountName() {
        const account = this.account;

        return (
            account?.iban ??
            account?.address ??
            ''
        );
    }

    public get isLoading() {
        return [
            ApiState.Idle,
            ApiState.Pending
        ].includes(this.state[MerchantProfileTab.Finance].apiState);
    }

    public get isError() {
        return Object.is(
            this.state[MerchantProfileTab.Finance].apiState,
            ApiState.Failed
        );
    }

    public get isEmpty() {
        const finance = this.state[MerchantProfileTab.Finance];

        return Object.is(
            this.state[MerchantProfileTab.Finance].apiState,
            ApiState.Succeeded
        ) && [
            finance[AccountType.Bank],
            finance[AccountType.Crypto]
        ].every(accounts => !accounts.length);
    }

    public get merchantSettings() {
        return this.merchantRepository.merchantSettings;
    }

    public get isMerchantSettingsLoading() {
        return this.merchantRepository.isMerchantSettingsLoading;
    }

    public get isMerchantSettingsUninitialized() {
        return this.merchantRepository.isMerchantSettingsUninitialized;
    }

    public get isMerchantSettingsSucceeded() {
        return this.merchantRepository.isMerchantSettingsSucceeded;
    }

    public selectPayoutAccount(account: MerchantPayoutAccount): void {
        this.dispatch({
            type: MerchantProfileActionType.SelectPayoutAccount,
            payload: account
        });
    }

    public async fetchMerchantSettings(): Promise<void> {
        return this.merchantRepository.fetchMerchantSettings();
    }

    public async fetchPayoutAccounts(): Promise<void> {
        if (this.shouldSendRequest(this.state[MerchantProfileTab.Finance].apiState)) {
            return;
        }

        this.dispatch({
            type: MerchantProfileActionType.FetchPayoutAccounts,
            payload: {
                apiState: ApiState.Pending
            }
        });

        const [
            payoutAccountsSettledRequest,
            payoutCryptoAccountsSettledRequest
        ] = await Promise.allSettled([
            this.getPayoutAccountsRequest(),
            this.getPayoutCryptoAccountsRequest()
        ]);

        const bankAccounts = (
            fromPaginateResourceResponse(
                getPromiseSettledResourceResult(payoutAccountsSettledRequest)
            )
        );

        const cryptoAccounts = (
            fromPaginateResourceResponse(
                getPromiseSettledResourceResult(payoutCryptoAccountsSettledRequest)
            )
        );

        this.dispatch({
            type: MerchantProfileActionType.FetchPayoutAccounts,
            payload: {
                apiState: ApiState.Succeeded,
                account: (
                    bankAccounts.at(0) ??
                    cryptoAccounts.at(0) ??
                    null
                ),
                [AccountType.Bank]: bankAccounts,
                [AccountType.Crypto]: cryptoAccounts
            }
        });
    }

    public async createPayoutAccount(account: MerchantProfileFinance): Promise<void> {
        const response = await this.createPayoutAccountRequest(account);

        this.dispatch({
            type: MerchantProfileActionType.CreatePayoutAccount,
            payload: this.throwOnFailedResponse(response).data
        });
    }

    public updateMerchantSettings(merchantSettings: MerchantSettingsActionPayload) {
        return this.merchantRepository.updateMerchantSettings(merchantSettings);
    }

    public updateMerchant(merchant: Partial<Merchant>): Promise<void> {
        return this.merchantRepository.updateMerchant(merchant);
    }

    public async updatePayoutAccount(account: MerchantPayoutAbstractAccount): Promise<void> {
        const response = await this.updatePayoutAccountRequest(account);

        this.dispatch({
            type: MerchantProfileActionType.UpdatePayoutAccount,
            payload: this.throwOnFailedResponse(response).data
        });
    }

    public async deletePayoutAccount(account: MerchantPayoutAbstractAccount): Promise<void> {
        const response = await this.deletePayoutAccountRequest(account);
        this.throwOnFailedResponse(response);

        this.dispatch({
            type: MerchantProfileActionType.DeletePayoutAccount,
            payload: account
        });
    }

    public async createOrDeleteMerchantGroupPivot(merchantProfileInfoForm: MerchantProfileInfoForm) {
        if (merchantProfileInfoForm.initialValues.groups) {
            await this.merchantRepository.deleteMerchantGroupPivot(merchantProfileInfoForm.initialValues);
        }

        if (merchantProfileInfoForm.values.groups) {
            await this.merchantRepository.createMerchantGroupPivot(merchantProfileInfoForm.values);
        }
    }

    public reset(): void {
        this.dispatch({
            type: MerchantProfileActionType.Reset,
            payload: undefined
        });
    }
}
