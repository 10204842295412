import { createSlice } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";
import sliceMixin from "infrastructure/store/reducers/sliceMixin";
import {
    transactionRequestFulfilled,
    transactionsRequestFulfilled,
    transactionsRequestLoading,
    transactionsRequestRejected
} from "./reducers";
import initialState, { sliceToken } from "./state";
import {
    getBankSdkThunk,
    getInNotificationsThunk,
    getOutNotificationsThunk,
    getTransactionDetailsThunk,
    getTransactionHistoryThunk,
    getTransactionsThunk
} from "./thunks";
import type { TransactionsState } from "./types";

const slice = createSlice({
    name: sliceToken,
    initialState,
    reducers: sliceMixin<TransactionsState>(initialState),
    extraReducers: builder =>
        builder
            // Transactions
            .addCase(getTransactionsThunk.pending, transactionsRequestLoading)
            .addCase(getTransactionsThunk.fulfilled, transactionsRequestFulfilled)
            .addCase(getTransactionsThunk.rejected, transactionsRequestRejected)
            // Transaction details
            .addCase(getTransactionDetailsThunk.pending, state => {
                state.transactionDetailsLoadingState = ApiState.Pending;
            })
            .addCase(getTransactionDetailsThunk.fulfilled, transactionRequestFulfilled)
            .addCase(getTransactionDetailsThunk.rejected, state => {
                state.transactionDetailsLoadingState = ApiState.Failed;
            })
            // Transaction Bank SDK
            .addCase(getBankSdkThunk.pending, state => {
                state.transactionsLoadingState = ApiState.Pending;
            })
            .addCase(getBankSdkThunk.fulfilled, state => {
                state.transactionsLoadingState = ApiState.Succeeded;
            })
            .addCase(getBankSdkThunk.rejected, state => {
                state.transactionsLoadingState = ApiState.Failed;
            })
            // Transaction history
            .addCase(getTransactionHistoryThunk.pending, state => {
                state.transactionHistoryLoadingState = ApiState.Pending;
            })
            .addCase(getTransactionHistoryThunk.fulfilled, state => {
                state.transactionHistoryLoadingState = ApiState.Succeeded;
            })
            .addCase(getTransactionHistoryThunk.rejected, state => {
                state.transactionHistoryLoadingState = ApiState.Failed;
            })
            // Notifications
            .addCase(getInNotificationsThunk.pending, state => {
                state.notificationsInLoadingState = ApiState.Pending;
            })
            .addCase(getInNotificationsThunk.fulfilled, state => {
                state.notificationsInLoadingState = ApiState.Succeeded;
            })
            .addCase(getInNotificationsThunk.rejected, state => {
                state.notificationsInLoadingState = ApiState.Failed;
            })
            .addCase(getOutNotificationsThunk.pending, state => {
                state.notificationsOutLoadingState = ApiState.Pending;
            })
            .addCase(getOutNotificationsThunk.fulfilled, state => {
                state.notificationsOutLoadingState = ApiState.Succeeded;
            })
            .addCase(getOutNotificationsThunk.rejected, state => {
                state.notificationsOutLoadingState = ApiState.Failed;
            })
});

export const {
    name,
    actions: {
        changeExportLoading,
        resetState
    }
} = slice;

export default slice.reducer;
