import { useRequest } from "hooks";
import type {
    InNotification,
    NotificationsResponse,
    OutNotification,
    TransactionActionResponse,
    TransactionDetailResponse,
    TransactionHistory,
    TransactionHistoryRequestParams,
    TransactionsResponse
} from "./types";
import {
    checkTransactionStatusThunk,
    closePingOrderThunk,
    fakeTransactionRedirectThunk,
    getBankSdkThunk,
    getInNotificationsThunk,
    getOutNotificationsThunk,
    getTransactionDetailsThunk,
    getTransactionHistoryThunk,
    getTransactionsThunk,
    refundThunk,
    sendTransactionNotificationThunk,
    settlePingOrderThunk
} from "./thunks";
import { changeExportLoading, resetState } from "./slice";
import type { ID, ThunkReturnType } from "types";
import { TableGridMode } from "consts/table";
import useResetState from "hooks/useResetState";
import useExportTable from "hooks/useExportTable";
import { ApiRouteTypes } from "consts/enpoints/api";

const useActions = (mode = TableGridMode.Transaction) => {
    const transactionStrategies = new Map<TableGridMode, any>()
        .set(TableGridMode.Transaction, getTransactionsThunk)
        .set(TableGridMode.BankSdk, getBankSdkThunk);

    const getTransactions = useRequest<ThunkReturnType<TransactionsResponse>, string>({
        thunk: transactionStrategies.get(mode)!
    });

    const getTransactionDetails = useRequest<ThunkReturnType<TransactionDetailResponse>, ID>({
        thunk: getTransactionDetailsThunk
    });

    const getTransactionHistory = useRequest<
        ThunkReturnType<TransactionHistory>,
        TransactionHistoryRequestParams
    >({ thunk: getTransactionHistoryThunk });

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetTransactions
    });

    const resetTransactions = useResetState(resetState);

    const getInNotifications = useRequest<ThunkReturnType<NotificationsResponse<InNotification>>, string>({
        thunk: getInNotificationsThunk
    });

    const getOutNotifications = useRequest<ThunkReturnType<NotificationsResponse<OutNotification>>, string>({
        thunk: getOutNotificationsThunk
    });

    const sendTransactionNotification = useRequest<ThunkReturnType<TransactionActionResponse>, string>({
        thunk: sendTransactionNotificationThunk
    });

    const checkTransactionStatus = useRequest<ThunkReturnType<TransactionActionResponse>, string>({
        thunk: checkTransactionStatusThunk
    });

    const fakeTransactionRedirect = useRequest<ThunkReturnType<TransactionActionResponse>, string>({
        thunk: fakeTransactionRedirectThunk
    });
    const refundTransactionAction = useRequest<ThunkReturnType<TransactionActionResponse>, string>({
        thunk: refundThunk
    });

    // TODO: fix typing
    const settlePingOrder = useRequest<ThunkReturnType<any>, string>({
        thunk: settlePingOrderThunk
    });

    const closePingOrder = useRequest<ThunkReturnType<any>, string>({
        thunk: closePingOrderThunk
    });

    return {
        resetTransactions,
        getTransactions,
        getTransactionDetails,
        getTransactionHistory,
        downloadCsv,
        getInNotifications,
        getOutNotifications,
        sendTransactionNotification,
        checkTransactionStatus,
        fakeTransactionRedirect,
        settlePingOrder,
        closePingOrder,
        refundTransactionAction
    };
};

export default useActions;
