import useExportTable from "hooks/useExportTable";
import {
    updateOrAddEntityById,
    changeExportMerchantUsersLoading as changeExportLoading,
    resetMerchantUsers as resetMerchantUsersAction,
    updateOrAddMerchantByIdUsers as updateOrAddMerchantByIdUsersAction,
    deleteMerchantUser as deleteMerchantUserAction
} from "./slice";
import { ApiRouteTypes } from "consts/enpoints/api";
import { useAppDispatch, useRequest } from "hooks";
import type { ID, ThunkReturnType } from "types";
import type { User, UsersResponse } from "features/users/types";
import { getMerchantByIdUsersThunk, getMerchantUsersThunk } from "./thunks";

const useMerchantUserActions = () => {
    const dispatch = useAppDispatch();

    const getMerchantUsers = useRequest<ThunkReturnType<UsersResponse>, string | undefined>({
        thunk: getMerchantUsersThunk
    });

    const getMerchantByIdUsers = useRequest<ThunkReturnType<UsersResponse>, string | undefined>({
        thunk: getMerchantByIdUsersThunk
    });

    const downloadCsv = useExportTable({
        changeExportLoading,
        apiResourceType: ApiRouteTypes.GetMerchantUsers
    });

    const updateOrAddMerchantUserById = (payload: User) =>
        dispatch(updateOrAddEntityById(payload));

    const resetMerchantUsers = () =>
        dispatch(resetMerchantUsersAction());

    const updateOrAddMerchantByIdUsers = (payload: User) =>
        dispatch(updateOrAddMerchantByIdUsersAction(payload));

    const deleteMerchantUser = (payload: ID) => dispatch(deleteMerchantUserAction(payload));

    return {
        updateOrAddMerchantUserById,
        updateOrAddMerchantByIdUsers,
        getMerchantUsers,
        getMerchantByIdUsers,
        downloadCsv,
        resetMerchantUsers,
        deleteMerchantUser
    };
};

export default useMerchantUserActions;
