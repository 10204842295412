import type {
    Merchant,
    MerchantSettings
} from "features/merchants/types";
import type { UsersResponse } from "features/users/types";
import type { DomainAction, ResourceResponse } from "types";
import type { IDomainRepository } from "hooks";
import { ApiState } from "infrastructure/api";
import type { UseSimpleRequestReturnCallbackArg } from "hooks/useRequest";
import type { MerchantsGroupsPivot } from "features/pivots/types";

export const enum MerchantDetailActionType {
    FetchMerchantSettings = 'MerchantDetailActionType:FetchMerchantSettings',
    FetchMerchantUsers = 'MerchantDetailActionType:FetchMerchantUsers',
    UpdateMerchant = 'MerchantDetailActionType:UpdateMerchant',
    UpdateMerchantSettings = 'MerchantDetailActionType:UpdateMerchantSettings',
    Reset = 'MerchantDetailActionType:Reset'
};

export type MerchantSettingsState = {
    readonly apiState: ApiState;
    readonly data: MerchantSettings | null;
};

export type MerchantDetailRepositoryState = {
    readonly merchantSettings: MerchantSettingsState;
    readonly merchantUsers: {
        apiState: ApiState;
        data: UsersResponse['data'];
    };
};

export type MerchantSettingsActionPayload = Partial<MerchantSettings>;

export type MerchantDetailRepositoryAction =
    | DomainAction<
        MerchantDetailActionType.FetchMerchantSettings,
        MerchantSettingsState
    >
    | DomainAction<
        MerchantDetailActionType.FetchMerchantUsers,
        MerchantDetailRepositoryState['merchantUsers']
    >
    | DomainAction<
        MerchantDetailActionType.UpdateMerchant,
        Partial<Merchant>
    >
    | DomainAction<
        MerchantDetailActionType.UpdateMerchantSettings,
        MerchantSettingsActionPayload
    >
    | DomainAction<
        MerchantDetailActionType.Reset,
        undefined
    >;

export interface MerchantSettingsRepository {
    isMerchantSettingsLoading: boolean;

    isMerchantSettingsUninitialized: boolean;

    isMerchantSettingsSucceeded: boolean;

    merchantSettings: MerchantDetailRepositoryState['merchantSettings']['data'];

    fetchMerchantSettings(): Promise<void>;

    updateMerchantSettings(
        merchantSettings: MerchantSettingsActionPayload,
        requestSettings?: UseSimpleRequestReturnCallbackArg
    ): Promise<ResourceResponse<MerchantSettings>>;
}

export interface MerchantUsersRepository {
    isMerchantUsersLoading: boolean;

    isMerchantUsersUninitialized: boolean;

    merchantUsers: MerchantDetailRepositoryState['merchantUsers']['data'];

    fetchMerchantUsers(): Promise<void>;
}

export interface MerchantGroupRepository {
    createMerchantGroupPivot(
        merchant: Partial<Merchant>,
        requestSettings?: UseSimpleRequestReturnCallbackArg
    ): Promise<ResourceResponse<MerchantsGroupsPivot>>;

    deleteMerchantGroupPivot(
        merchant: Partial<Merchant>,
        requestSettings?: UseSimpleRequestReturnCallbackArg
    ): Promise<ResourceResponse<null>>;
}

export interface MerchantRepository {
    updateMerchant(merchant: Partial<Merchant>): Promise<void>;
}

export interface MerchantDetailRepository extends
    MerchantRepository,
    MerchantGroupRepository,
    MerchantSettingsRepository,
    MerchantUsersRepository,
    IDomainRepository
{
    readonly state: MerchantDetailRepositoryState;

    isLoading: boolean;
}
