import { WebRoutes } from "consts/enpoints/web";
import UnsavedChanges from "ui/layouts/UnsavedChanges";
import { EnabledMerchants } from "ui/organizms/Merchants";
import { useTicketingSystemStateSelectorsContext } from "../TicketingSystemProvider";
import TicketingSystemMasterConnections from "./TicketingSystemMasterConnections";
import TicketingSystemLegend from "./TicketingSystemLegend";

const TicketingSystemMerchantList = () => {
    const {
        selectTicketingSystemApiState,
        selectIsTicketingSystemSettingsChanged
    } = useTicketingSystemStateSelectorsContext();

    return (
        <UnsavedChanges
            subscriberKey='Merchants.TicketingSystem'
            checkUnsavedChanges={selectIsTicketingSystemSettingsChanged}
        >
            <EnabledMerchants
                selectApiState={selectTicketingSystemApiState}
                title="Ticketing System"
                heading="Ticketing System"
                links={[{ name: 'Ticketing System' }]}
                Route={WebRoutes.Merchants.TicketingSystem}
                action={(
                    <TicketingSystemMasterConnections />
                )}
                secondaryAction={TicketingSystemLegend}
            />
        </UnsavedChanges>
    );
};

export default TicketingSystemMerchantList;
