import { type Dispatcher, DomainRepository } from "hooks/useDomainState";
import {
    MerchantDetailActionType,
    type MerchantDetailRepository,
    type MerchantDetailRepositoryState,
    type MerchantSettingsActionPayload
} from "./types";
import type { PaginateResourceResponse, ResourceResponse } from "types";
import type { Merchant, MerchantSettings } from "features/merchants/types";
import type { UsersResponse } from "features/users/types";
import type { MerchantsGroupsPivot } from "features/pivots/types";
import { ApiState } from "infrastructure/api";
import type { UseSimpleRequestReturnCallbackArg } from "hooks/useRequest";
import { fromPaginateResourceResponse } from "util/api";

export class Repository extends DomainRepository<
    MerchantDetailRepositoryState,
    MerchantDetailActionType
> implements MerchantDetailRepository {
    public constructor(
        public readonly state: MerchantDetailRepositoryState,
        public readonly dispatch: Dispatcher<MerchantDetailActionType>,
        private readonly getMerchantSettingsRequest: () => Promise<PaginateResourceResponse<MerchantSettings>>,
        private readonly getMerchantUsersRequest: () => Promise<UsersResponse>,
        private readonly updateMerchantRequest: (merchant: Partial<Merchant>) => Promise<void>,
        private readonly updateMerchantSettingsRequest: (
            merchantSettings: MerchantSettingsActionPayload,
            requestSettings?: UseSimpleRequestReturnCallbackArg
        ) => Promise<ResourceResponse<MerchantSettings>>,
        private readonly createMerchantGroupPivotRequest: (
            merchant: Partial<Merchant>,
            requestSettings?: UseSimpleRequestReturnCallbackArg
        ) => Promise<ResourceResponse<MerchantsGroupsPivot>>,
        private readonly deleteMerchantGroupPivotRequest: (
            merchant: Partial<Merchant>,
            requestSettings?: UseSimpleRequestReturnCallbackArg
        ) => Promise<ResourceResponse<null>>
    ) {
        super(state, dispatch);
    }

    public get isMerchantSettingsLoading() {
        return Object.is(
            this.state.merchantSettings.apiState,
            ApiState.Pending
        );
    }

    public get isMerchantSettingsUninitialized() {
        return Object.is(
            this.state.merchantSettings.apiState,
            ApiState.Idle
        );
    }

    public get isMerchantSettingsSucceeded() {
        return Object.is(
            this.state.merchantSettings.apiState,
            ApiState.Succeeded
        );
    }

    public get isMerchantUsersLoading() {
        return Object.is(
            this.state.merchantUsers.apiState,
            ApiState.Pending
        );
    }

    public get isMerchantUsersUninitialized() {
        return Object.is(
            this.state.merchantUsers.apiState,
            ApiState.Idle
        );
    }

    public get merchantSettings() {
        return this.state.merchantSettings.data;
    }

    public get merchantUsers() {
        return this.state.merchantUsers.data;
    }

    public get isLoading() {
        return this.isMerchantSettingsLoading;
    }

    public async fetchMerchantSettings(): Promise<void> {
        if (this.shouldSendRequest(this.state.merchantSettings.apiState)) {
            return;
        }

        this.dispatch({
            type: MerchantDetailActionType.FetchMerchantSettings,
            payload: {
                apiState: ApiState.Pending
            }
        });

        const response = await this.getMerchantSettingsRequest();

        this.dispatch({
            type: MerchantDetailActionType.FetchMerchantSettings,
            payload: {
                apiState: ApiState.Succeeded,
                data: fromPaginateResourceResponse(response).at(0)
            }
        });
    }

    public async fetchMerchantUsers() {
        if (this.shouldSendRequest(this.state.merchantUsers.apiState)) {
            return;
        }

        this.dispatch({
            type: MerchantDetailActionType.FetchMerchantUsers,
            payload: {
                apiState: ApiState.Pending
            }
        });

        const response = await this.getMerchantUsersRequest();

        this.dispatch({
            type: MerchantDetailActionType.FetchMerchantUsers,
            payload: {
                apiState: ApiState.Succeeded,
                data: this.throwOnFailedResponse(response).data
            }
        });
    }

    public updateMerchant(merchant: Partial<Merchant>) {
        return this.updateMerchantRequest(merchant);
    }

    public createMerchantGroupPivot(merchant: Partial<Merchant>, requestSettings?: UseSimpleRequestReturnCallbackArg) {
        return this.createMerchantGroupPivotRequest(merchant, requestSettings);
    }

    public deleteMerchantGroupPivot(merchant: Partial<Merchant>, requestSettings?: UseSimpleRequestReturnCallbackArg) {
        return this.deleteMerchantGroupPivotRequest(merchant, requestSettings);
    }

    public async updateMerchantSettings(
        mechantSettings: MerchantSettingsActionPayload,
        requestSettings?: UseSimpleRequestReturnCallbackArg
    ) {
        const response = await this.updateMerchantSettingsRequest(mechantSettings, requestSettings);

        this.dispatch({
            type: MerchantDetailActionType.UpdateMerchantSettings,
            payload: this.throwOnFailedResponse(response).data
        });

        return response;
    }

    public reset() {
        this.dispatch({
            type: MerchantDetailActionType.Reset,
            payload: undefined
        });
    }
}

export default Repository;
