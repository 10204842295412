import { createSlice } from "@reduxjs/toolkit";
import sliceMixin from "infrastructure/store/reducers/sliceMixin";
import {
    merchantGroupsRequestFulfilled,
    merchantGroupsRequestLoading,
    merchantGroupsRequestRejected,
    requestFulfilled,
    requestLoading,
    requestRejected,
    merchantCreateRequestLoading,
    merchantCreateRequestFulfilled,
    merchantCreateRequestRejected,
    merchantUpdateRequestFulfilled,
    merchantUsersRequestLoading,
    merchantUsersRequestFulfilled,
    merchantUsersRequestRejected,
    updateOrAddMerchantByIdUsers as updateOrAddMerchantByIdUsersReducer,
    merchantLedgersRequestLoading,
    merchantLedgersRequestFulfilled,
    merchantLedgersRequestRejected,
    updateOrAddMerchantLedger,
    deleteMerchantUser as deleteMerchantUserReducer
} from "./reducers";
import initialState, { sliceToken } from "./state";
import {
    getMerchantByIdUsersThunk,
    getMerchantGroupsThunk,
    getMerchantLedgersThunk,
    getMerchantUsersThunk,
    getMerchantsThunk,
    postMerchantThunk,
    putMerchantLedgersThunk,
    putMerchantThunk
} from "./thunks";
import type { MerchantsState, Tabs } from "./types";
import { MerchantTabs } from "consts/merchants";
import { resourceSliceFactory } from "infrastructure/store/state";
import { fromPaginateResourceResponse } from "util/api";

const slice = createSlice({
    name: sliceToken,
    initialState,
    reducers: {
        ...sliceMixin<MerchantsState, Tabs>(initialState),
        changeMerchantsLoadingState: (state, { payload }) => {
            state.merchants.tableLoadingState = payload;
        },
        changeMerchantsSlice: (state, { payload }) => {
            state.merchants.tableSlice = payload.data;
        },
        changeMerchantGroupsLoadingState: (state, { payload }) => {
            state[MerchantTabs.Merchants].bootstrapData.merchantGroups.loadingState = payload;
        },
        changeMerchantGroupsSlice: (state, { payload }) => {
            state[MerchantTabs.Merchants].bootstrapData.merchantGroups.slice = fromPaginateResourceResponse(payload);
        },
        changeExportMerchantUsersLoading: (state, { payload }) => {
            state[MerchantTabs.MerchantUsers].exportLoadingState = payload;
            state.merchantUsers.exportLoadingState = payload;
        },
        changeExportMerchantLedgersLoading: (state, { payload }) => {
            state.ledgers.exportLoadingState = payload;
        },
        resetMerchantUsers: state => {
            state.merchantUsers = resourceSliceFactory();
        },
        resetMerchantLedgers: state => {
            state.ledgers = resourceSliceFactory();
        },
        updateOrAddMerchantByIdUsers: updateOrAddMerchantByIdUsersReducer,
        deleteMerchantUser: deleteMerchantUserReducer
    },
    extraReducers: builder => {
        builder
            // Get Merchants
            .addCase(getMerchantsThunk.pending, requestLoading)
            .addCase(getMerchantsThunk.fulfilled, requestFulfilled)
            .addCase(getMerchantsThunk.rejected, requestRejected)
            // Get Merchant Users
            .addCase(getMerchantUsersThunk.pending, requestLoading)
            .addCase(getMerchantUsersThunk.fulfilled, requestFulfilled)
            .addCase(getMerchantUsersThunk.rejected, requestRejected)
            // Get Merchant By Id users
            .addCase(getMerchantByIdUsersThunk.pending, merchantUsersRequestLoading)
            .addCase(getMerchantByIdUsersThunk.fulfilled, merchantUsersRequestFulfilled)
            .addCase(getMerchantByIdUsersThunk.rejected, merchantUsersRequestRejected)
            // Ledgers
            .addCase(getMerchantLedgersThunk.pending, merchantLedgersRequestLoading)
            .addCase(getMerchantLedgersThunk.fulfilled, merchantLedgersRequestFulfilled)
            .addCase(getMerchantLedgersThunk.rejected, merchantLedgersRequestRejected)
            //Put Ledger
            .addCase(putMerchantLedgersThunk.pending, merchantLedgersRequestLoading)
            .addCase(putMerchantLedgersThunk.fulfilled, updateOrAddMerchantLedger)
            .addCase(putMerchantLedgersThunk.rejected, merchantLedgersRequestRejected)
            // Post Merchant
            .addCase(postMerchantThunk.pending, merchantCreateRequestLoading)
            .addCase(postMerchantThunk.fulfilled, merchantCreateRequestFulfilled)
            .addCase(postMerchantThunk.rejected, merchantCreateRequestRejected)
            // Put Merchant
            .addCase(putMerchantThunk.pending, merchantCreateRequestLoading)
            .addCase(putMerchantThunk.fulfilled, merchantUpdateRequestFulfilled)
            .addCase(putMerchantThunk.rejected, merchantCreateRequestRejected)
            // Get Merchant Groups
            .addCase(getMerchantGroupsThunk.pending, merchantGroupsRequestLoading)
            .addCase(getMerchantGroupsThunk.fulfilled, merchantGroupsRequestFulfilled)
            .addCase(getMerchantGroupsThunk.rejected, merchantGroupsRequestRejected)
    }
});

export const {
    name,
    actions: {
        changeExportLoading,
        changeExportMerchantUsersLoading,
        changeExportMerchantLedgersLoading,
        resetState,
        changeTab,
        updateOrAddEntityById,
        changeMerchantsLoadingState,
        changeMerchantsSlice,
        changeMerchantGroupsLoadingState,
        changeMerchantGroupsSlice,
        resetMerchantUsers,
        resetMerchantLedgers,
        updateOrAddMerchantByIdUsers,
        deleteMerchantUser
    }
} = slice;

export default slice.reducer;
