import type { AsyncThunk } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";
import {
    getBankSdk,
    getTransactionHistory,
    getTransactions,
    getInNotifications,
    getOutNotifications,
    getTransactionDetails,
    sendNotification,
    checkStatus,
    fakeRedirect,
    refund,
    settlePingOrder,
    closePingOrder
} from "features/transactions/api";
import type { RootState } from "infrastructure/store";
import { ApiRouteTypes } from "consts/enpoints/api";

import {
    InNotification,
    NotificationsResponse,
    OutNotification,
    TransactionAction,
    TransactionActionResponse,
    TransactionDetailResponse,
    TransactionHistory,
    TransactionHistoryRequestParams,
    TransactionsResponse
} from "./types";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import { urlSearchparamTransformer } from "util/transformers";
import { sliceToken } from "./state";
import type { ID, ThunkReturnType } from "types";

export const getTransactionsThunk: AsyncThunk<
    ThunkReturnType<TransactionsResponse>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetTransactions]),
    payloadCreator(getTransactions/*, urlSearchparamTransformer*/),
    {
        condition: (_, { getState }) => {
            const { transactions } = getState() as RootState;
            return ![ApiState.Pending].includes(transactions.transactionsLoadingState);
        }
    }
);

export const getTransactionDetailsThunk: AsyncThunk<
    ThunkReturnType<TransactionDetailResponse>,
    ID,
    {}
> = createAsyncThunk(
    concat([sliceToken, 'GetTransactionDetails']),
    payloadCreator(getTransactionDetails),
    {
        condition: (_, { getState }) => {
            const { transactions } = getState() as RootState;

            return ![ApiState.Pending].includes(transactions.transactionDetailsLoadingState);
        }
    }
);

export const getTransactionHistoryThunk: AsyncThunk<
    ThunkReturnType<TransactionHistory>,
    TransactionHistoryRequestParams,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetTransactionHistory]),
    payloadCreator(getTransactionHistory),
    {
        condition: (_, { getState }) => {
            const { transactions } = getState() as RootState;

            return ![ApiState.Pending]
                .includes(transactions.transactionHistoryLoadingState);
        }
    }
);

// TODO: fix types accordingly
export const getBankSdkThunk: AsyncThunk<any, any/*TransactionsResponse, FilterParams*/, {}> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetBankSdk]),
    payloadCreator(getBankSdk, urlSearchparamTransformer),
    {
        condition: (_, { getState }) => {
            const { transactions } = getState() as RootState;
            return ![ApiState.Pending].includes(transactions.transactionsLoadingState);
        }
    }
);

export const getInNotificationsThunk: AsyncThunk<
    ThunkReturnType<NotificationsResponse<InNotification>>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetInNotifications]),
    payloadCreator(getInNotifications),
    {
        condition: (_, { getState }) => {
            const { transactions } = getState() as RootState;

            return ![ApiState.Pending]
                .includes(transactions.notificationsInLoadingState);
        }
    }
);

export const getOutNotificationsThunk: AsyncThunk<
    ThunkReturnType<NotificationsResponse<OutNotification>>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetOutNotifications]),
    payloadCreator(getOutNotifications),
    {
        condition: (_, { getState }) => {
            const { transactions } = getState() as RootState;

            return ![ApiState.Pending]
                .includes(transactions.notificationsOutLoadingState);
        }
    }
);

export const sendTransactionNotificationThunk: AsyncThunk<
    ThunkReturnType<TransactionActionResponse>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, TransactionAction.SendNotification]),
    payloadCreator(sendNotification)
);

export const checkTransactionStatusThunk: AsyncThunk<
    ThunkReturnType<TransactionActionResponse>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, TransactionAction.CheckStatus]),
    payloadCreator(checkStatus)
);

export const fakeTransactionRedirectThunk: AsyncThunk<
    ThunkReturnType<TransactionActionResponse>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, TransactionAction.FakeRedirect]),
    payloadCreator(fakeRedirect)
);
export const refundThunk: AsyncThunk<
    ThunkReturnType<TransactionActionResponse>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, TransactionAction.Refund]),
    payloadCreator(refund)
);
// Ping Transactions
export const settlePingOrderThunk: AsyncThunk<
    ThunkReturnType<any>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, TransactionAction.SettlePingOrder]),
    payloadCreator(settlePingOrder)
);

export const closePingOrderThunk: AsyncThunk<
    ThunkReturnType<any>,
    string,
    {}
> = createAsyncThunk(
    concat([sliceToken, TransactionAction.ClosePingOrder]),
    payloadCreator(closePingOrder)
);
