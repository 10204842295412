import { useRef } from 'react';
import type { GridEventListener } from "@mui/x-data-grid";
import { useAppStore, useTypedSelector } from 'hooks';
import useMerchantActions from "features/merchants/useMerchantActions";
import type { Merchant } from 'features/merchants/types';
import { selectIsCountriesLoading } from 'features/general/selectors';
import { selectMerchantById } from 'features/merchants/selectors';
import { useResourceContext } from 'ui/widgets/Resource';
import usePersistMerchant from './usePersistMerchant';
import useSyncMerchantUpdates from './useSyncMerchantUpdates';

export default function useMerchants() {
    const store = useAppStore();
    const isCountriesLoading = useTypedSelector(selectIsCountriesLoading);

    const {
        getMerchants: getResource,
        downloadCsv
    } = useMerchantActions();

    const { onOpen } = useResourceContext();

    usePersistMerchant({
        onMerchantsLoaded: merchantId => {
            if (!merchantId) {
                return;
            }

            const merchant = selectMerchantById(merchantId)(store.getState());

            if (!merchant) {
                return;
            }

            onOpen(merchant);
        }
    });

    useSyncMerchantUpdates();

    const merchantRef = useRef<Partial<Merchant>>({});

    const onRowClick: GridEventListener<'rowClick'> = ({ row }) => {
        if (isCountriesLoading) {
            return;
        }

        onOpen(row);
    };

    return {
        downloadCsv,
        getResource,
        onRowClick,
        resource: merchantRef.current
    };
}
