import { memo } from "react";
import Resource from "ui/widgets/Resource";
import useLedgerResource from "./useLedgerResource";
import { TableGridMode } from "consts/table";
import {
    selectIsMerchantLedgersUninitialized,
    selectIsMerchantLedgersLoading,
    selectMerchantLedgersSlice
} from "features/merchants/selectors";
import LedgerResourceToolbar from "./LedgerResourceToolbar";
import Ledger from "./Ledger";
import { useSearchParamsNoop, useUserPreferencesNoop } from "ui/widgets/Table";
import * as merchants from "consts/merchants";
import { GridRenderCellParams } from "@mui/x-data-grid";
import LedgerEnabled from "../../../../RenderTableCells/LedgerEnabled";

const LedgerResource = () => (
    <Resource
        name='Ledger'
        useResource={useLedgerResource}
        mode={TableGridMode.MerchantLedger}
        selectIsTableUninitialized={selectIsMerchantLedgersUninitialized}
        selectIsTableLoading={selectIsMerchantLedgersLoading}
        selectTableDataSlice={selectMerchantLedgersSlice}
        LayoutProps={{
            sx: {
                m: 0
            }
        }}
        Toolbar={LedgerResourceToolbar}
        Detail={Ledger}
        adapters={{
            useSearchParams: useSearchParamsNoop,
            useUserPreferences: useUserPreferencesNoop
        }}
        columns={[
            {
                field: merchants.Filters.isEnabled,
                headerName: 'Enabled',
                headerAlign: 'center',
                align: 'center',
                minWidth: 160,
                renderCell: (params: GridRenderCellParams) => (
                    <LedgerEnabled
                        {...params}
                    />
                )
            }
        ]}
    />
);

export default memo(LedgerResource);
