import { useState } from "react";
import { MerchantProfileTab } from "./types";
import useMerchantProfileInfo from "./useMerchantProfileInfo";
import useMerchantProfileFinance from "./useMerchantProfileFinance";
import { getDirtyFields, isFormChanged } from "util/forms";
import { withSubscription } from "providers/ContextPublisher";
import useMerchantDetailsTabContext from "../../useMerchantDetailsTabContext";
import type { MerchantProfileRepository } from "../repository";
import useMerchantDetail from "../../useMerchantDetail";
import useMarchantPayoutSettings from "./useMarchantPayoutSettings";
import { useFormableTab } from "ui/widgets/StatefulTabs";
import useGeneralActions from "features/general/useActions";
import { NotificationSeverity } from "features/general/types";

export default function useMerchantProfile() {
    const [isSaving, setSavingState] = useState(false);

    const { showNotication } = useGeneralActions();

    const { merchant } = useMerchantDetail();
    const repository = useMerchantDetailsTabContext<MerchantProfileRepository>();

    const state = {
        [MerchantProfileTab.Info]: withSubscription(
            useMerchantProfileInfo(),
            'MerchantProfileTab.Info'
        ),
        [MerchantProfileTab.Finance]: withSubscription(
            useMerchantProfileFinance(),
            'MerchantProfileTab.Finance'
        ),
        [MerchantProfileTab.PayoutSettings]: withSubscription(
            useMarchantPayoutSettings(),
            'MerchantProfileTab.PayoutSettings'
        )
    };

    const {
        hasActiveChanges,
        canSubmit,
        activeTab,
        tabManager,
        message,
        selectState,
        validate,
        resetActiveChanges,
        onChange
    } = useFormableTab<MerchantProfileTab>({
        state,
        entries: [
            ['1', MerchantProfileTab.Info],
            ['2', MerchantProfileTab.Finance]
        ]
    });

    const selectInfo = () =>
        state[MerchantProfileTab.Info];

    const selectFinance = () =>
        state[MerchantProfileTab.Finance];

    const selectPayoutSettings = () =>
        state[MerchantProfileTab.PayoutSettings];

    const updateMerchant = () =>
        repository.updateMerchant({
            ...getDirtyFields({
                ...state[MerchantProfileTab.Info],
                initialValues: merchant
            }),
            coreId: merchant.coreId
        });

    const createOrDeleteMerchantGroupPivot = () =>
        repository.createOrDeleteMerchantGroupPivot(state[MerchantProfileTab.Info]);

    const updateMerchantSettings = () => {
        if (!repository.merchantSettings) {
            return Promise.resolve();
        }

        return repository.updateMerchantSettings({
            ...getDirtyFields({
                ...state[MerchantProfileTab.PayoutSettings],
                initialValues: repository.merchantSettings
            }),
            coreId: repository.merchantSettings.coreId
        });
    };

    const updateMerchantPayoutAccount = () => {
        if (!repository.account) {
            return Promise.resolve();
        }

        return repository.updatePayoutAccount({
            ...getDirtyFields({
                ...state[MerchantProfileTab.Finance],
                initialValues: repository.account
            }),
            coreId: repository.account.coreId,
            merchantId: merchant.coreId
        });
    };

    const saveChanges = async () => {
        let merchantPromise;
        let merchantSettingsPromise;
        let payoutAccountPromise;


        if (isFormChanged(state[MerchantProfileTab.Info])) {
            setSavingState(true);

            merchantPromise = createOrDeleteMerchantGroupPivot()
                .finally(updateMerchant);
        }

        if (isFormChanged(state[MerchantProfileTab.Finance])) {
            setSavingState(true);

            payoutAccountPromise = updateMerchantPayoutAccount();
        }

        if (isFormChanged(state[MerchantProfileTab.PayoutSettings])) {
            setSavingState(true);

            merchantSettingsPromise = updateMerchantSettings();
        }

        await Promise.allSettled([
            merchantPromise,
            merchantSettingsPromise,
            payoutAccountPromise
        ]);

        resetActiveChanges();
        setSavingState(false);

        showNotication({
            message: 'Merchant profile has been updated',
            severity: NotificationSeverity.Success
        });
    };

    return {
        isSaving,
        activeTab,
        tabManager,
        message,
        selectState,
        selectFinance,
        selectInfo,
        selectPayoutSettings,
        validate,
        canSubmit,
        hasActiveChanges,
        isFormChanged,
        saveChanges,
        onChange
    };
};
