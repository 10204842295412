import { ApiState } from "infrastructure/api";
import type { WritableDraft } from "immer/dist/internal";
import type { TransactionDetailResponse, TransactionsResponse, TransactionsState } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";
import { getApiStateFromResponse } from "util/api";
import { compareIds } from "util/support";
import { ResourceResponse, ThunkReturnType } from "types";

export const transactionsRequestLoading = (state: WritableDraft<TransactionsState>) => {
    state.transactionsLoadingState = ApiState.Pending;
};

export const transactionsRequestFulfilled = (
    state: WritableDraft<TransactionsState>,
    { payload }: PayloadAction<ThunkReturnType<TransactionsResponse>>
) => {
    state.transactionsLoadingState = ApiState.Succeeded;
    state.transactionSlice = payload!.data;
};

export const transactionRequestFulfilled = (
    state: WritableDraft<TransactionsState>,
    { payload }: PayloadAction<ThunkReturnType<TransactionDetailResponse>>
) => {
    state.transactionDetailsLoadingState = ApiState.Succeeded;

    const tableSlice = state.transactionSlice;

    if (!tableSlice) {
        return;
    }

    state.transactionSlice!.data = tableSlice.data.map(transactionEntity => {
        if (compareIds(transactionEntity.coreId, payload!.data.coreId)) {
            return {
                ...transactionEntity,
                ...payload!.data
            };
        }

        return transactionEntity;
    });
};

export const transactionsRequestRejected = (
    state: WritableDraft<TransactionsState>,
    { payload }: PayloadAction<unknown>
) => {
    state.transactionsLoadingState = getApiStateFromResponse(
        payload as ResourceResponse<null>,
        ApiState.Failed
    );
};
