import { useEffect, useRef } from "react";
import { useUnsavedChangesContext } from "ui/widgets/UnsavedChanges";
import type { UnsavedChangesProps } from "./types";

export default function useUnsavedChanges({
    subscriberKey,
    checkUnsavedChanges
}: UnsavedChangesProps) {
    const {
        addUnsavedChangesSubscriber,
        removeUnsavedChangesSubscriber
    } = useUnsavedChangesContext();

    const helpers = {
        subscriberKey,
        addUnsavedChangesSubscriber,
        removeUnsavedChangesSubscriber
    };
    const helpersRef = useRef(helpers);
    helpersRef.current = helpers;

    if (subscriberKey) {
        addUnsavedChangesSubscriber([subscriberKey, checkUnsavedChanges]);
    }

    useEffect(() => () => {
        const {
            subscriberKey,
            removeUnsavedChangesSubscriber
        } = helpersRef.current;

        if (subscriberKey) {
            removeUnsavedChangesSubscriber(subscriberKey);
        }
    }, []);
};
