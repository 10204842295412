import { FilterValueModifier } from "consts/filters";
import {
    Filters,
    OPEN_BANKING_METHOD_TAG,
    OpenBankingEmptyResultStatus,
    OpenBankingMessageForAccountsResultStatus,
    OpenBankingMessageResultStatus,
    OpenBankingResultStatus
} from "consts/transactions";
import { Filters as CommonFilters } from 'consts/filters';
import { RequestQueryMapper } from "util/request-query-mapper";
import { Relations } from "consts/transactions";
import { MerchantGroupName } from "consts/merchants";

const queryExtensionMap = new Map<
    string,
    (values: string[], requestQueryMapper: RequestQueryMapper) => void
>()
    .set(Filters.obResultStatus, ([obResultStatus]: string[], requestQueryMapper: RequestQueryMapper) =>
        new Map<
            OpenBankingResultStatus,
            () => RequestQueryMapper
        >()
            .set(OpenBankingResultStatus.BankInfoNotSubmitted, () =>
                requestQueryMapper
                    .doesntHaveRelations(Relations.FlowContext)
            )
            .set(OpenBankingResultStatus.BankInfoSubmitted, () =>
                requestQueryMapper
                    .containsIn(Filters.obResultStatus, OpenBankingEmptyResultStatus.BankInfoSubmitted)
            )
            .set(OpenBankingResultStatus.PaymentFinalizedWaitingConfirmation, () =>
                requestQueryMapper
                    .containsIn(Filters.obResultStatus, OpenBankingMessageResultStatus.PaymentFinalizedWaitingConfirmation)
                    .checkWithModifier(CommonFilters.updatedAt, FilterValueModifier.IsNotNull)
            )
            .set(OpenBankingResultStatus.RedirectedConsumerToAuthorizePayment, () =>
                requestQueryMapper
                    .containsIn(Filters.obResultStatus, OpenBankingMessageResultStatus.RedirectedConsumerToAuthorizePayment)
                    .checkWithModifier(CommonFilters.updatedAt, FilterValueModifier.IsNotNull)
            )
            .set(OpenBankingResultStatus.RequestedConsumerToAuthorizePayment, () =>
                requestQueryMapper
                    .containsIn(Filters.obResultStatus, OpenBankingMessageResultStatus.RequestedConsumerToAuthorizePayment)
                    .checkWithModifier(CommonFilters.updatedAt, FilterValueModifier.IsNotNull)
            )
            .set(OpenBankingResultStatus.RequestedPasswordForConsumer, () =>
                requestQueryMapper
                    .containsIn(Filters.obResultStatus, OpenBankingMessageResultStatus.RequestedPasswordForConsumer)
                    .checkWithModifier(CommonFilters.updatedAt, FilterValueModifier.IsNotNull)
            )
            .set(OpenBankingResultStatus.RequestedMoreInfoFromConsumer, () =>
                requestQueryMapper
                    .containsIn(Filters.obResultStatus, OpenBankingMessageResultStatus.RequestedMoreInfoFromConsumer)
                    .checkWithModifier(CommonFilters.updatedAt, FilterValueModifier.IsNotNull)
            )
            .set(OpenBankingResultStatus.RequestedOptionFromConsumer, () =>
                requestQueryMapper
                    .containsIn(Filters.obResultStatus, OpenBankingMessageResultStatus.RequestedOptionFromConsumer)
                    .checkWithModifier(CommonFilters.updatedAt, FilterValueModifier.IsNotNull)
            )
            // .set(OpenBankingResultStatus.ErrorHasOccurred, () =>
            //                 requestQueryMapper
            //                     .containsIn(Filters.obResultStatus, OpenBankingMessageResultStatus.ErrorHasOccurred)
            //                     .checkWithModifier(CommonFilters.updatedAt, FilterValueModifier.IsNotNull)
            //             )
            .set(OpenBankingResultStatus.RetryToFinalizePaymentAfterPeriod, () =>
                requestQueryMapper
                    .containsIn(Filters.obResultStatus, OpenBankingMessageResultStatus.RetryToFinalizePaymentAfterPeriod)
                    .checkWithModifier(CommonFilters.updatedAt, FilterValueModifier.IsNotNull)
            )
            .set(OpenBankingResultStatus.AccountInitFinalizedWaitingPaymentInit, () =>
                requestQueryMapper
                    .containsIn(Filters.obResultStatus, OpenBankingMessageForAccountsResultStatus.AccountInitFinalizedWaitingPaymentInit)
                    .checkWithModifier(CommonFilters.updatedAt, FilterValueModifier.IsNull)
            )
            .set(OpenBankingResultStatus.RedirectedConsumerForAccountConfirmation, () =>
                requestQueryMapper
                    .containsIn(Filters.obResultStatus, OpenBankingMessageForAccountsResultStatus.RedirectedConsumerForAccountConfirmation)
                    .checkWithModifier(CommonFilters.updatedAt, FilterValueModifier.IsNull)
            )
            .set(OpenBankingResultStatus.RequestedConsumerToAuthorizeAccount, () =>
                requestQueryMapper
                    .containsIn(Filters.obResultStatus, OpenBankingMessageForAccountsResultStatus.RequestedConsumerToAuthorizeAccount)
                    .checkWithModifier(CommonFilters.updatedAt, FilterValueModifier.IsNull)
            )
            .set(OpenBankingResultStatus.RetryToFinalizeAccountAfterPeriod, () =>
                requestQueryMapper
                    .containsIn(Filters.obResultStatus, OpenBankingMessageForAccountsResultStatus.RetryToFinalizeAccountAfterPeriod)
                    .checkWithModifier(CommonFilters.updatedAt, FilterValueModifier.IsNull)
            )
            .get(Number(obResultStatus))?.()
            .containsIn(Filters.methodTag, OPEN_BANKING_METHOD_TAG)
    )
    .set(Filters.parentId, ([parentId]: string[], requestQueryMapper: RequestQueryMapper) => {
        if (Number(parentId)) {
            return requestQueryMapper
                .containsIn(Filters.parentId, parentId);
        }

        requestQueryMapper
            .contains(Filters.parentId, parentId);
    })
    .set(Filters.isEcommerce, ([isEcommerce]: string[], requestQueryMapper: RequestQueryMapper) => [
        () => requestQueryMapper
            .containsNotIn(Relations.MerchantGroupName, MerchantGroupName.ECommerce),
        () => requestQueryMapper
            .containsIn(Relations.MerchantGroupName, MerchantGroupName.ECommerce)
    ][Number(isEcommerce)]());

export default function useRequestQueryOverrideDecorator(
    requestQueryAggregator: (
        fontendQueryParams: URLSearchParams,
        backendQueryParams: URLSearchParams
    ) => void
) {
    return (
        fontendQueryParams: URLSearchParams,
        backendQueryParams: URLSearchParams
    ) => {
        RequestQueryMapper.applyWithCustomQueryAggregator({
            fontendQueryParams,
            backendQueryParams,
            queryExtensionMap
        });

        return requestQueryAggregator(fontendQueryParams, backendQueryParams);
    };
};
