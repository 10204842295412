import type { AsyncThunk } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiState } from "infrastructure/api";

import type { RootState } from "infrastructure/store";
import { ApiRouteTypes } from "consts/enpoints/api";
import { payloadCreator } from "util/api";
import { concat } from "util/support";
import { sliceToken } from "./state";
import type {
    Ledger,
    LedgerResponse,
    LedgersResponse,
    Merchant,
    MerchantGroup,
    MerchantGroupsResponse,
    MerchantResponse,
    MerchantsResponse
} from "./types";
import {
    getMerchants,
    createOrUpdateMerchant,
    getMerchantGroups,
    createOrUpdateMerchantSettings,
    getMerchantUsers,
    getLedgers,
    createOrUpdateLedger,
    getMerchantById
} from "features/merchants/api";
import type { ThunkReturnType } from "types";
import { selectIsNotMor } from "features/auth/selectors";
import { MerchantTabs } from "consts/merchants";
import { createMerchantsGroupsPivot } from "features/pivots/api";
import { toIds } from "util/option";
import type { UsersResponse } from "features/users/types";
import { toMerchantActiveStatus } from "./helpers";

export const getMerchantsThunk: AsyncThunk<
    ThunkReturnType<MerchantsResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetMerchants]),
    payloadCreator(getMerchants),
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;

            return (
                selectIsNotMor(getState()) &&
                ![ApiState.Pending].includes(merchants[MerchantTabs.Merchants].tableLoadingState)
            );
        }
    }
);

export const getMerchantUsersThunk: AsyncThunk<
    ThunkReturnType<UsersResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetMerchantUsers]),
    payloadCreator(getMerchantUsers),
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;

            return ![ApiState.Pending].includes(merchants[MerchantTabs.MerchantUsers].tableLoadingState);
        }
    }
);

export const getMerchantByIdUsersThunk: AsyncThunk<
    ThunkReturnType<UsersResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetMerchantByIdUsers]),
    payloadCreator(getMerchantUsers),
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;

            return ![ApiState.Pending].includes(merchants.merchantUsers.tableLoadingState);
        }
    }
);

export const getMerchantLedgersThunk: AsyncThunk<
    ThunkReturnType<LedgersResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetLedgers]),
    payloadCreator(getLedgers),
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;

            return ![ApiState.Pending].includes(merchants.ledgers.tableLoadingState);
        }
    }
);

export const putMerchantLedgersThunk: AsyncThunk<
ThunkReturnType<LedgerResponse>,
Partial<Ledger>,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PutLedgers]),
    payloadCreator(createOrUpdateLedger),
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;

            return ![ApiState.Pending].includes(merchants.ledgers.tableLoadingState);
        }
    }
);

export const postMerchantThunk: AsyncThunk<
    ThunkReturnType<MerchantResponse>,
    Partial<Merchant & MerchantGroup>,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PostMerchant]),
    async ({
        doNotBlacklistOnChargeback,
        doNotBlacklistOnRefund,
        ...merchant
    }: Partial<Merchant & MerchantGroup>, { rejectWithValue, fulfillWithValue }) => {
        const merchantResponse = await createOrUpdateMerchant(merchant);

        if (!merchantResponse.ok) {
            return rejectWithValue(merchantResponse.clone());
        }

        const merchantPayload: MerchantResponse = await merchantResponse.json();

        if (!merchantPayload.success) {
            return rejectWithValue(merchantPayload);
        }

        const { coreId: merchantId, isActive } = merchantPayload.data;

        await Promise.allSettled([
            ...toIds(merchant.groups).map(groupId =>
                createMerchantsGroupsPivot({
                    merchantId,
                    merchantGroupId: groupId,
                    doNotBlacklistOnChargeback,
                    doNotBlacklistOnRefund
                })
            ),
            createOrUpdateMerchantSettings({
                merchantId,
                payoutCurrency: merchant.payoutCurrency,
                apiId: merchantId,
                apiGuid: String(merchantId),
                cpKey: String(merchantId),
                cpSecret: String(merchantId),
                sharedKey: String(merchantId),
                activeStatus: toMerchantActiveStatus(isActive!)
            })
        ]);

        const fulfillValue: ThunkReturnType<MerchantResponse> = await getMerchantById(merchantId)
            .then(response => (
                response.ok
                    ? response.json()
                    : merchantPayload
            ))
            .catch(() => merchantPayload);

        fulfillWithValue(fulfillValue);

        return fulfillValue;
    },
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;

            return ![ApiState.Pending].includes(merchants[MerchantTabs.Merchants].entityLoadingState);
        }
    }
);

export const putMerchantThunk: AsyncThunk<
    ThunkReturnType<MerchantResponse>,
    Partial<Merchant>,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.PutMerchant]),
    payloadCreator(createOrUpdateMerchant),
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;

            return ![ApiState.Pending].includes(merchants[MerchantTabs.Merchants].entityLoadingState);
        }
    }
);

export const getMerchantGroupsThunk: AsyncThunk<
    ThunkReturnType<MerchantGroupsResponse>,
    string | undefined,
    {}
> = createAsyncThunk(
    concat([sliceToken, ApiRouteTypes.GetMerchantGroups]),
    payloadCreator(getMerchantGroups),
    {
        condition: (_, { getState }) => {
            const { merchants } = getState() as RootState;
            const { merchantGroups } = merchants[MerchantTabs.Merchants].bootstrapData;

            return ![
                ApiState.Pending,
                ApiState.Succeeded
            ].includes(merchantGroups.loadingState);
        }
    }
);
