import { useForm as useFormHook } from "hooks";
import { converStringtoArray } from "util/transformers";
import { trimObjectValues } from "util/support";
import { toBooleanSwitch } from "util/casts";
import {
    FormField,
    type SetupPaymentMethodBasicInfoForm,
    type SetupPaymentMethodBasicInfo
} from "./types";
import useValidationSchema from "./useValidationSchema";

export const getInitialValues = ({
    forCurrencies,
    forCountries,
    requireSpecIn,
    sendPaymentReceiptMail,
    sendPaymentRequestMail,
    isAssignable,
    isRefundable,
    ...restInitialValues
}: Partial<SetupPaymentMethodBasicInfo> = {}): SetupPaymentMethodBasicInfo => trimObjectValues({
    [FormField.ForCurrencies]: converStringtoArray(forCurrencies),
    [FormField.ForCountries]: converStringtoArray(forCountries),
    [FormField.Name]: '',
    [FormField.Tag]: '',
    [FormField.DayToHoldOfflineFunds]: 0,
    [FormField.Provider]: '',
    [FormField.RequireSpecIn]: toBooleanSwitch(requireSpecIn),
    [FormField.SendPaymentReceiptMail]: toBooleanSwitch(sendPaymentReceiptMail),
    [FormField.SendPaymentRequestMail]: toBooleanSwitch(sendPaymentRequestMail),
    [FormField.IsAssignable]: toBooleanSwitch(isAssignable),
    [FormField.IsRefundable]: toBooleanSwitch(isRefundable),
    ...restInitialValues
});

export const useForm = (setupPaymentMethodBasicInfoFormProps: SetupPaymentMethodBasicInfoForm) =>
    useFormHook({
        enableReinitialize: true,
        ...setupPaymentMethodBasicInfoFormProps,
        validateOnMount: true,
        validationSchema: useValidationSchema(),
        initialValues: getInitialValues(setupPaymentMethodBasicInfoFormProps.initialValues)
    });
